/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Avatar } from 'antd-mobile';
import { MdCameraAlt } from 'react-icons/md';
import IconButton from 'components/button/IconButton';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/input/CustomInput';
import TextButton from 'components/button/TextButton';
import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';
import AvatarUpload from 'components/input/AvatarUpload';
import { uploadAvatar } from 'services/auth.service';
import { isSuccessCode } from 'utils/common';

type FormDataType = {
  phoneNumber?: string;
  email?: string;
};
const Storage = new LocalStorage();

function EditProfileContainer() {
  const { t } = useTranslation();
  const accountDetail = Storage.getStorageItem(STORAGE_KEY.ACCOUNT_INFO);
  const [formData, setFormData] = useState<FormDataType>({
    phoneNumber: accountDetail?.account?.phoneNumber,
    email: accountDetail?.account?.email,
  });

  const handleUpdateLoadAvatar = async (base64: string) => {
    const response = await uploadAvatar({
      base64,
    });
    const { code, data } = response.data;
    if (isSuccessCode(code)) {
      const accountInfo = Storage.getStorageItem(STORAGE_KEY.ACCOUNT_INFO);
      accountInfo.accountDetail.avatarUrl = data.avatarUrl;
      Storage.setStorageItem(STORAGE_KEY.ACCOUNT_INFO, accountInfo);

      return true;
    } else return false;
  };

  return (
    <div className={styles.wrapper}>
      <AvatarUpload onUpload={handleUpdateLoadAvatar} />
      <div className={styles.form}>
        <CustomInput
          title={t('title.phone-number')}
          placeholder={t('placeholder.type-phone-number')}
          value={formData.phoneNumber}
          required
          disabled
        />
        <CustomInput
          title={t('title.email')}
          placeholder={t('placeholder.type-email')}
          value={formData.email}
          disabled
        />
      </div>
      {/* <TextButton
        title={t('button.save-change')}
        variant="solid"
        color="warning"
        style={{
          marginTop: '24px',
        }}
        disabled
      /> */}
    </div>
  );
}

export default EditProfileContainer;
