import { Badge } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils/common';

type Props = {
  children?: React.ReactNode;
  content?: string;
};

function CustomBadge({ children, content }: Props) {
  return (
    <Badge className={styles.point} content={content ? showData(content) : ''}>
      {children}
    </Badge>
  );
}

export default React.memo(CustomBadge);
