import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const Storage = new LocalStorage();
interface State {
  isLoading: boolean;
  isShowToast: boolean;
  contractDetailColor: string;
  invoiceDetailColor: string;
  transactionDetailColor: string;
  language: string;
  toastContent: {
    isError?: boolean;
    code?: number;
    message?: string;
    description?: {
      code?: number;
      message?: string;
    };
  };
  countInfo: {
    notify: number;
    debitInvoice: number;
  };
}

const initialState: State = {
  isLoading: false,
  isShowToast: false,
  toastContent: {},
  contractDetailColor: '',
  invoiceDetailColor: '',
  transactionDetailColor: '',
  language: Storage.getStorageItem(STORAGE_KEY.LANGUAGE) || 'vi',
  countInfo: {
    notify: 0,
    debitInvoice: 0,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state: State, action) => {
      if (state.isLoading != action.payload) {
        state.isLoading = action.payload;
      }
    },
    showToast: (state: State, action) => {
      state.isShowToast = true;
      state.toastContent = action.payload;
    },
    clearToast: (state: State) => {
      state.isShowToast = false;
    },
    setContractDetailColor: (state: State, action: PayloadAction<string>) => {
      state.contractDetailColor = action.payload;
    },
    setInvoiceDetailColor: (state: State, action: PayloadAction<string>) => {
      state.invoiceDetailColor = action.payload;
    },
    setTransactionDetailColor: (state: State, action: PayloadAction<string>) => {
      state.transactionDetailColor = action.payload;
    },
    setCountInfo: (
      state: State,
      action: PayloadAction<{
        notify: number;
        debitInvoice: number;
      }>,
    ) => {
      state.countInfo = action.payload;
    },
    setLanguage: (state: State, action: PayloadAction<string>) => {
      state.language = action.payload;
      Storage.setStorageItem(STORAGE_KEY.LANGUAGE, action.payload);
    },
  },
});

export const {
  showToast,
  setLoading,
  clearToast,
  setContractDetailColor,
  setInvoiceDetailColor,
  setTransactionDetailColor,
  setLanguage,
  setCountInfo,
} = commonSlice.actions;
export default commonSlice.reducer;
