import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useParams } from 'react-router';
import { Skeleton } from 'antd-mobile';
import { NotifyInfoType } from 'types/notify.type';
import { getNotificationDetail, readNotify } from 'services/notify.service';
import { delayNavigate, formatDateTime, isSuccessCode, showData } from 'utils/common';

type Props = {};

export default function NotifyDetailContainer({}: Props) {
  const { notifyId } = useParams();
  const [notifyDetail, setNotifyDetail] = useState<NotifyInfoType>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await getNotificationDetail({
        notificationId: notifyId,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setNotifyDetail(data);
        if (!(data as NotifyInfoType).isRead) {
          readNotify({ notificationId: notifyId });
        }
      }
      delayNavigate(() => {
        setIsLoading(false);
      });
    };

    getData();
  }, [notifyId]);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Skeleton animated style={{ height: 'var(--font-xl)' }} />
      ) : (
        <p
          className="color-black font-xl font-bold"
          style={{ textAlign: 'left', lineHeight: 'var(--font-xxl)' }}
        >
          {showData(notifyDetail?.template?.titleVi)}
        </p>
      )}
      {isLoading ? (
        <Skeleton animated style={{ height: 'var(--font-xs)', width: '30%' }} />
      ) : (
        <p className="color-grey-01 font-xs font-light">
          {showData(formatDateTime(notifyDetail?.createdAt))}
        </p>
      )}
      {isLoading ? (
        <Skeleton.Paragraph animated lineCount={4} style={{ height: 'var(--font-xl)' }} />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: showData(notifyDetail?.notificationMobile),
          }}
        />
      )}
    </div>
  );
}
