import {
  MdAssignmentLate,
  MdAssignmentReturned,
  MdAssignmentTurnedIn,
  MdBuild,
  MdClose,
  MdDesktopMac,
  MdDoorFront,
  MdFactCheck,
  MdFeed,
  MdOutlineWallet,
} from 'react-icons/md';

import { RiFileWarningFill } from 'react-icons/ri';

import { BsFileXFill } from 'react-icons/bs';

export const NOTIFY_TEMPLATE = {
  CONTRACT: {
    CREATE_CONTRACT_SUCCESS: {
      Icon: <MdAssignmentTurnedIn fontSize="24px" color="var(--white-01)" />,
      color: 'var(--green)',
    },
    CONTRACT_IS_SOON_EXPIRED: {
      Icon: <MdAssignmentLate fontSize="24px" color="var(--white-01)" />,
      color: 'var(--orange)',
    },
    CONTRACT_IS_EXPIRED: {
      Icon: <MdAssignmentReturned fontSize="24px" color="var(--white-01)" />,
      color: 'var(--red)',
    },
  },
  INVOICE: {
    INVOICE_WAITING_FOR_PAYMENT: {
      Icon: <MdFeed fontSize="24px" color="var(--white-01)" />,
      color: 'var(--blue)',
    },
    INVOICE_IS_SOON_EXPIRED: {
      Icon: <RiFileWarningFill fontSize="24px" color="var(--white-01)" />,
      color: 'var(--orange)',
    },
    INVOICE_IS_EXPIRED: {
      Icon: <BsFileXFill fontSize="24px" color="var(--white-01)" />,
      color: 'var(--red)',
    },
    INVOICE_WAS_CANCELED: {
      Icon: <MdClose fontSize="24px" color="var(--white-01)" />,
      color: 'var(--red)',
    },
    DEPOSIT_INVOICE_WAITING_FOR_PAYMENT: {
      Icon: <MdOutlineWallet fontSize="24px" color="var(--white-01)" />,
      color: 'var(--purple)',
    },
  },
  TRANSACTION: {
    TRANSACTION_SUCCESS: {
      Icon: <MdFactCheck fontSize="24px" color="var(--white-01)" />,
      color: 'var(--green)',
    },
    DEPOSIT_TRANSACTION_SUCCESS: {
      Icon: <MdOutlineWallet fontSize="24px" color="var(--white-01)" />,
      color: 'var(--green)',
    },
  },
  ROOM: {
    BOOKING_ROOM: {
      Icon: <MdDoorFront fontSize="24px" color="var(--white-01)" />,
      color: 'var(--green)',
    },
  },
  MAINTENANCE: {
    CHANGE_STATUS_TASK_IN_MAINTENANCE: {
      Icon: <MdBuild fontSize="24px" color="var(--white-01)" />,
      color: 'var(--black)',
    },
  },
  SYSTEM: {
    NOTIFICATION_ENABLED: {
      Icon: <MdDesktopMac fontSize="24px" color="var(--white-01)" />,
      color: 'var(--grey-02)',
    },
  },
};
