import { Button, Image } from 'antd-mobile';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { IoLogoApple } from 'react-icons/io5';
import { IoLogoAndroid } from 'react-icons/io';
import { isAndroid, isMobileSafari } from 'react-device-detect';
import { getAppLogo, getAppName } from 'utils/common';

function InstallOverlay() {
  const { t } = useTranslation();
  const installPrompt = useRef<any>();
  const [isShowGuide, setIsShowGuide] = useState(false);

  const handleInstall = async () => {
    if (!installPrompt.current) {
      return;
    }
    await installPrompt.current.prompt();
  };

  const handleShowGuide = () => {
    setIsShowGuide(true);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      installPrompt.current = event;
    });
  }, []);

  return isShowGuide ? (
    <div className={styles.guide}>
      <p
        className="font-xl color-white font-bold"
        style={{
          textAlign: 'center',
        }}
      >
        {t('paragraph.install-guide')}
      </p>
      <p className="font-lg color-white font-medium">{`${t('title.step')} 1:`}</p>
      <Image src="/images/guide/step-01.jpg" />
      <p className="font-lg color-white font-medium">{`${t('title.step')} 2:`}</p>
      <Image src="/images/guide/step-02.jpg" />
      <p className="font-lg color-white font-medium">{`${t('title.step')} 3:`}</p>
      <Image src="/images/guide/step-03.jpg" />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.logo}>
        <div
          style={{
            width: '100px',
            height: '100px',
            background: 'var(--white-01)',
            borderRadius: '100px',
            overflow: 'hidden',
            marginBottom: '24px',
            border: '2px solid var(--white-01)',
          }}
        >
          <Image
            src={getAppLogo()}
            alt="logo"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
        <p className={`font-xxxl color-white font-bold ${styles.animation}`}>{getAppName()}</p>
        {isMobileSafari ? (
          <Button
            color="warning"
            style={{
              fontSize: 'var(--font-lg)',
            }}
            onClick={handleShowGuide}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '6px',
              }}
            >
              <IoLogoApple
                fontSize="26px"
                style={{
                  position: 'relative',
                  bottom: '2px',
                }}
              />
              {t('button.install')}
            </span>
          </Button>
        ) : isAndroid ? (
          <Button
            color="warning"
            style={{
              fontSize: 'var(--font-lg)',
            }}
            onClick={handleInstall}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '6px',
              }}
            >
              <IoLogoAndroid
                fontSize="26px"
                style={{
                  position: 'relative',
                  bottom: '0px',
                }}
              />
              {t('button.install')}
            </span>
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default React.memo(InstallOverlay);
