import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';
import InvoiceContainer from 'containers/InvoiceContainer';

type Props = {};

export default function InvoicePage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.invoice')}>
      <InvoiceContainer />
    </WrapperLayout>
  );
}
