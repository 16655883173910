import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';
import ContractContainer from 'containers/ContractContainer';

type Props = {};

export default function ContractPage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.contract')}>
      <ContractContainer />
    </WrapperLayout>
  );
}
