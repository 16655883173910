import { AxiosGW } from './axios.service';

export const getBuildings = async () => {
  return AxiosGW.get('/api/v1/property/buildings', {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getBranchDetail = async () => {
  return AxiosGW.get('/api/v1/property/branch/detail', {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getBranchConfig = async (domain: string) => {
  return AxiosGW.get('/api/v1/property/branch-detail', {
    params: {
      appDomain: domain,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};
