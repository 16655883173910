import React, { ComponentType } from 'react';
import styles from './styles.module.scss';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Button, ButtonProps } from 'antd-mobile';
import { IconBaseProps } from 'react-icons';
import { showData } from 'utils/common';

type Props = {
  Icon: ComponentType<IconBaseProps>;
  title?: string;
};

function SettingItem({ Icon, title, ...nest }: Props & ButtonProps) {
  return (
    <Button className={styles.wrapper} {...nest}>
      <Icon fontSize="24px" color="var(--orange)" />
      <p
        className="font-light color-black font-md"
        style={{
          flex: 1,
          textAlign: 'left',
        }}
      >
        {showData(title)}
      </p>
      <MdOutlineKeyboardArrowRight fontSize="24px" color="var(--orange)" />
    </Button>
  );
}

export default React.memo(SettingItem);
