export const DEFAULT_EMPTY = '-';

export const CONTRACT_STATUS = {
  AVAILABLE: 'available',
  EXPIRING_SOON: 'expiring-soon',
  EXPIRED: 'expired',
};

export const INVOICE_STATUS = {
  WAITING: 'WAITING_FOR_PAYMENT',
  PAID: 'PAID',
  PARTIAL: 'PARTIAL',
  CANCEL: 'CANCELED',
  EXPIRED: 'EXPIRED',
};

export const TRANSACTION_STATUS = {
  INCOME: 'INCOME',
  EXPENSE: 'EXPENSE',
};

export const MAINTENANCE_STATUS = {
  WAIT_FOR_APPROVAL: 'WAIT_FOR_APPROVAL',
  APPROVED: 'APPROVED',
  IN_PROGRESS: 'IN_PROGRESS',
  ACCEPTANCE: 'ACCEPTANCE',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
};

export const DELAY_TRANSITION = 300;

export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const ALL_OPTION = 'ALL';
