import { BuildingInfoType } from 'types/property.type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getListBuildingAction } from './actions';
import { isSuccessCode } from 'utils/common';
import { CustomResponseType } from 'types/common.type';
import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';

const Storage = new LocalStorage();

interface State {
  buildings: BuildingInfoType[];
  currentBuilding?: BuildingInfoType;
}

const initialState: State = {
  buildings: [],
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setCurrentBuilding: (state: State, action: PayloadAction<BuildingInfoType>) => {
      state.currentBuilding = action.payload;
      Storage.setStorageItem(STORAGE_KEY.BUILDING_ID, action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getListBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.buildings = action.payload.data;
          const storedBuildingId = Storage.getStorageItem(STORAGE_KEY.BUILDING_ID);
          if (storedBuildingId) {
            state.currentBuilding = (action.payload.data as BuildingInfoType[]).find(
              (building) => building.id == storedBuildingId,
            );
          } else {
            state.currentBuilding = action.payload.data[0];
            Storage.setStorageItem(STORAGE_KEY.BUILDING_ID, action.payload.data[0].id);
          }
        }
      },
    );
  },
});

export const { setCurrentBuilding } = propertySlice.actions;
export default propertySlice.reducer;
