import React, { ComponentType, useMemo } from 'react';
import styles from './styles.module.scss';
import { IconBaseProps } from 'react-icons';
import { showData } from 'utils/common';
import { Skeleton } from 'antd-mobile';
import useDirection from 'hooks/useDirection';

type Props = {
  color?: 'default' | 'primary' | 'success' | 'warning' | 'danger' | undefined;
  Icon: ComponentType<IconBaseProps>;
  title?: string;
  content?: string;
  isSkeleton?: boolean;
  link?: string;
};

function InfoCard({ color, Icon, title, content, isSkeleton = false, link = '' }: Props) {
  const { goTo } = useDirection();
  const bgColor = useMemo(() => {
    switch (color) {
      case 'success':
        return 'var(--light-green-01)';
      case 'warning':
        return 'var(--light-orange-01)';
      case 'danger':
        return 'var(--light-red-01)';
      case 'primary':
        return 'var(--light-blue-01)';
    }
  }, [color]);

  const mainColor = useMemo(() => {
    switch (color) {
      case 'success':
        return 'var(--green)';
      case 'warning':
        return 'var(--orange)';
      case 'danger':
        return 'var(--red)';
      case 'primary':
        return 'var(--blue)';
    }
  }, [color]);

  return (
    <div className={styles.cardItem}>
      <div
        className={styles.iconBox}
        style={{
          background: bgColor,
        }}
      >
        <Icon fontSize="30px" color={mainColor} />
      </div>
      <div className={styles.cardContent}>
        <p className="color-grey-02 font-xs font-medium">{showData(title)}</p>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
              width: '100%',
            }}
          />
        ) : link ? (
          <a className="link font-md font-bold" onClick={goTo(link)}>
            {showData(content)}
          </a>
        ) : (
          <p className="color-black font-md font-bold">{showData(content)}</p>
        )}
      </div>
    </div>
  );
}

export default React.memo(InfoCard);
