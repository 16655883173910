/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import {
  MdAssignmentLate,
  MdAssignmentReturned,
  MdAssignmentTurnedIn,
  MdDoorFront,
  MdRoom,
} from 'react-icons/md';
import { showData } from 'utils/common';
import { CONTRACT_STATUS } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { Skeleton, SpaceProps } from 'antd-mobile';
import Chip from 'components/common/Chip';
import { ContractType } from '@/types/common.type';

type Props = {
  status?: ContractType;
  contractCode?: string;
  expiredDate?: string;
  room?: string;
  isHideChip?: boolean;
  isSkeleton?: boolean;
};

function ContractCard({
  status,
  contractCode,
  expiredDate,
  room,
  isHideChip = false,
  isSkeleton = false,
  ...nest
}: Props & SpaceProps) {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (status) {
      case CONTRACT_STATUS.AVAILABLE:
        return 'var(--green)';
      case CONTRACT_STATUS.EXPIRING_SOON:
        return 'var(--orange)';
      case CONTRACT_STATUS.EXPIRED:
        return 'var(--soft-red)';
      default:
        return 'var(--green)';
    }
  }, [status]);

  const chipColor = useMemo(() => {
    switch (status) {
      case CONTRACT_STATUS.AVAILABLE:
        return 'success';
      case CONTRACT_STATUS.EXPIRING_SOON:
        return 'warning';
      case CONTRACT_STATUS.EXPIRED:
        return 'danger';
      default:
        return 'success';
    }
  }, [status]);

  const iconStatus = useMemo(() => {
    switch (status) {
      case CONTRACT_STATUS.AVAILABLE:
        return <MdAssignmentTurnedIn fontSize="30px" color="var(--white-01)" />;
      case CONTRACT_STATUS.EXPIRING_SOON:
        return <MdAssignmentLate fontSize="30px" color="var(--white-01)" />;
      case CONTRACT_STATUS.EXPIRED:
        return <MdAssignmentReturned fontSize="30px" color="var(--white-01)" />;
      default:
        return <MdAssignmentTurnedIn fontSize="30px" color="var(--white-01)" />;
    }
  }, [status]);

  return (
    <div className={styles.contractCard} {...nest}>
      {isSkeleton ? (
        <Skeleton className={styles.iconBox} animated />
      ) : (
        <div
          className={styles.iconBox}
          style={{
            background: color,
          }}
        >
          {iconStatus}
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.group}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: '12px',
                width: '100%',
              }}
            />
          ) : (
            <p className="font-light font-xs color-grey-01">
              {t('title.expired-at')}: <span className="font-medium">{showData(expiredDate)}</span>
            </p>
          )}

          {!isHideChip && (
            <Chip color={chipColor} content={t(`title.${status}`)} isSkeleton={isSkeleton} />
          )}
        </div>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '24px',
              width: '100%',
            }}
          />
        ) : (
          <div className={styles.roomName}>
            <MdDoorFront fontSize="24px" color={color} />
            <p
              className="font-bold font-xxl"
              style={{
                color: color,
              }}
            >
              {showData(room)}
            </p>
          </div>
        )}
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '10px',
              width: '50%',
              display: 'block',
              textAlign: 'right',
              transform: 'translateX(100%)',
            }}
          />
        ) : (
          <p
            className="font-light font-us color-grey-01"
            style={{
              fontStyle: 'italic',
              textAlign: 'right',
              display: 'block',
              width: '100%',
            }}
          >
            #{showData(contractCode)}
          </p>
        )}
      </div>
    </div>
  );
}

export default React.memo(ContractCard);
