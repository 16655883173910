import { Divider, FloatingPanelRef, Image, Skeleton } from 'antd-mobile';
import styles from './styles.module.scss';
import BottomPanel from 'components/panel/BottomPanel';
import TransactionCard from 'components/card/TransactionCard';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getInvoiceDetail } from 'services/user.service';
import { useParams } from 'react-router';
import { InvoiceInfoType } from 'types/user.type';
import { convertToHTMLWithSup, formatPrice, isSuccessCode, showData } from 'utils/common';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch } from 'store/Store';
import { setInvoiceDetailColor } from 'store/common';
import { INVOICE_COLOR } from 'constants/color';
import EmptyCard from 'components/card/EmptyCard';
import { DELAY_TRANSITION, INVOICE_STATUS } from 'constants/common';
import Chip from 'components/common/Chip';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import { MdCurrencyExchange, MdOutlineError } from 'react-icons/md';

export default function InvoiceDetailContainer() {
  const { invoiceId } = useParams();
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceInfoType>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { goTo } = useDirection();
  const panelRef = useRef<FloatingPanelRef>();
  const [isLoading, setIsLoading] = useState(false);

  const chipColor = useMemo(() => {
    switch (invoiceDetail?.status) {
      case INVOICE_STATUS.WAITING:
        return 'primary';
      case INVOICE_STATUS.CANCEL:
        return 'default';
      case INVOICE_STATUS.PAID:
        return 'success';
      case INVOICE_STATUS.PARTIAL:
        return 'warning';
      case INVOICE_STATUS.EXPIRED:
        return 'danger';
      default:
        return 'primary';
    }
  }, [invoiceDetail?.status]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await getInvoiceDetail({ invoiceId });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setInvoiceDetail(data);
        setTimeout(() => {
          setIsLoading(false);
        }, DELAY_TRANSITION);
      }
      if (panelRef.current) {
        panelRef.current.setHeight(window.innerHeight * 0.4);
      }
    };
    getData();
  }, [invoiceId]);

  useEffect(() => {
    dispatch(
      setInvoiceDetailColor(
        INVOICE_COLOR[invoiceDetail?.status as keyof typeof INVOICE_COLOR] || 'var(--blue)',
      ),
    );
  }, [invoiceDetail?.status]);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.card}
        style={{
          height: window.innerHeight * 0.4,
        }}
      >
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-lg)',
            }}
          />
        ) : (
          <p className="color-black font-bold font-lg">
            {t('title.payment-period')}: {showData(invoiceDetail?.contractPeriod?.name)}
          </p>
        )}
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'calc(100% - 18px - 16px - 24px - 24px)',
            }}
          />
        ) : invoiceDetail?.status === INVOICE_STATUS.PAID ? (
          <Image src="/images/icons/svg/success.svg" width="100%" height="100%" />
        ) : invoiceDetail?.status === INVOICE_STATUS.CANCEL ? (
          <Image src="/images/icons/svg/fail.svg" width="100%" height="100%" />
        ) : invoiceDetail?.status === INVOICE_STATUS.PARTIAL ? (
          <EmptyCard
            Icon={MdOutlineError}
            text={t('paragraph.partial-not-support')}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        ) : invoiceDetail?.qrCode ? (
          <QRCode
            value={String(invoiceDetail?.qrCode)}
            style={{
              height: 'calc(100% - 18px - 16px - 24px - 24px)',
              maxWidth: '100%',
              width: '100%',
            }}
          />
        ) : (
          <EmptyCard
            text={t('paragraph.qr-code-error')}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        )}
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
            }}
          />
        ) : (
          <div className={styles.price}>
            <p className="color-black font-medium font-md">{t('title.total-invoice')}:</p>
            <p className="color-black font-bold font-md">
              {showData(
                invoiceDetail?.totalPrice ? formatPrice(Number(invoiceDetail?.totalPrice)) : '',
              )}
            </p>
          </div>
        )}
      </div>
      <BottomPanel
        refPanel={panelRef}
        anchors={[75, window.innerHeight * 0.4, window.innerHeight * 0.8, window.innerHeight - 56]}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p
            className={`font-xl font-bold color-black`}
            style={{
              textAlign: 'center',
            }}
          >
            {t('title.invoice-detail')}
          </p>
          <Chip
            color={chipColor}
            content={t(`chip.${invoiceDetail?.status}`)}
            isSkeleton={isLoading}
          />
        </div>

        <div className={styles.content}>
          {isLoading ? (
            <>
              <Skeleton animated style={{ height: 'var(--font-md)' }} />
              <Skeleton animated style={{ height: 'var(--font-md)' }} />
              <Skeleton animated style={{ height: 'var(--font-md)' }} />
              <Skeleton animated style={{ height: 'var(--font-md)' }} />
            </>
          ) : (
            invoiceDetail?.contractPeriodFees.map((periodFee) => {
              return (
                <div key={periodFee?.id} className={styles.item}>
                  <p
                    className={`color-black font-light font-md ${styles.left}`}
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {periodFee?.name}:
                    {periodFee?.contractFee && periodFee?.contractFee.serviceFeeType?.isMeter
                      ? `\n${periodFee?.startingNumber} ➞ ${periodFee?.endingNumber}`
                      : ''}
                  </p>
                  <p className={`color-black font-medium font-md ${styles.right}`}>
                    {showData(
                      periodFee?.totalPrice ? formatPrice(Number(periodFee?.totalPrice)) : '',
                    )}
                    {periodFee?.contractFee && (
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span
                          className="color-black font-light font-xs"
                          style={{
                            bottom: '1px',
                            position: 'relative',
                          }}
                        >
                          {` (${t('short-name.quantity')}: ${showData(periodFee?.amount)} `}
                        </span>
                        <span
                          className="color-black font-light font-xs"
                          style={{
                            position: 'relative',
                            bottom: '1px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: `${convertToHTMLWithSup(
                              String(
                                showData(periodFee?.contractFee?.serviceFeeType?.unit?.nameVi),
                              ),
                            )} )`,
                          }}
                        />
                      </span>
                    )}
                  </p>
                </div>
              );
            })
          )}
          <Divider
            style={{
              margin: '0',
            }}
          />

          {isLoading ? (
            <Skeleton animated style={{ height: 'var(--font-xl)' }} />
          ) : (
            <div className={styles.item}>
              <p className="color-black font-medium font-xl">{t('title.total-invoice')}:</p>
              <p className="color-black font-bold font-xl">
                {showData(
                  invoiceDetail?.totalPrice ? formatPrice(Number(invoiceDetail?.totalPrice)) : '',
                )}
              </p>
            </div>
          )}
          {invoiceDetail?.status === INVOICE_STATUS.PARTIAL && (
            <>
              <Divider
                style={{
                  margin: '0',
                }}
              />
              <div className={styles.item}>
                <p className="color-black font-light font-md">{t('title.amount-paid')}:</p>
                <p className="color-black font-bold font-md">
                  {showData(
                    invoiceDetail?.totalPrice
                      ? `-${formatPrice(Number(invoiceDetail?.transactionAmount))}`
                      : '',
                  )}
                </p>
              </div>
              <div className={styles.item}>
                <p className="color-black font-medium font-xl">{t('title.total')}:</p>
                <p className="color-black font-bold font-xl">
                  {showData(
                    invoiceDetail?.totalPrice
                      ? formatPrice(
                          Number(invoiceDetail?.totalPrice) -
                            Number(invoiceDetail?.transactionAmount),
                        )
                      : '',
                  )}
                </p>
              </div>
            </>
          )}

          <Divider
            className="color-grey-02 font-xs font-medium"
            style={{
              width: '100vw',
              position: 'relative',
              left: '-24px',
            }}
          >
            {t('title.transaction-history')}
          </Divider>
          {isLoading ? (
            <>
              <TransactionCard isSkeleton />
              <TransactionCard isSkeleton />
            </>
          ) : invoiceDetail?.contractFeeTransactions ? (
            invoiceDetail?.contractFeeTransactions?.map((transaction) => {
              return (
                <TransactionCard
                  key={transaction.id}
                  status={transaction.transactionType?.type}
                  transactionType={transaction.transactionType?.nameVi}
                  transactionAmount={transaction.transactionAmount}
                  transactionDate={transaction.purchaseDate}
                  onClick={goTo(
                    ROUTES.DETAIL_TRANSACTION.replace(':transactionId', String(transaction.id)),
                  )}
                />
              );
            })
          ) : (
            <EmptyCard
              Icon={MdCurrencyExchange}
              text={t('paragraph.no-transaction')}
              style={{
                height: '40px',
              }}
            />
          )}
        </div>
      </BottomPanel>
    </div>
  );
}
