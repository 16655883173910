import React from 'react';
import styles from './styles.module.scss';
import { MdArrowBack, MdHome } from 'react-icons/md';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { THEME_COLOR } from 'constants/color';
import { SpaceProps } from 'antd-mobile';
import MainPageTransition from 'components/animation/MainPageTransition';

type Props = {
  children?: React.ReactNode;
  title?: string;
  headerColor?: string;
};

function WrapperLayout({
  children,
  title,
  headerColor = 'var(--blue)',
  ...nest
}: Props & SpaceProps) {
  const { goBack, goTo } = useDirection();
  let color;
  switch (headerColor) {
    case 'var(--blue)':
      color = THEME_COLOR.BLUE;
      break;
    case 'var(--soft-orange)':
      color = THEME_COLOR.ORANGE;
      break;
    case 'var(--green)':
      color = THEME_COLOR.GREEN;
      break;
    case 'var(--soft-red)':
      color = THEME_COLOR.RED;
      break;
    case 'var(--grey-02)':
      color = THEME_COLOR.GRAY;
      break;
    default:
      color = THEME_COLOR.BLUE;
  }
  usePWATemplateColor(color);

  return (
    <div className={styles.container} {...nest}>
      <div
        className={styles.header}
        style={{
          background: headerColor,
        }}
      >
        <MdArrowBack color="var(--white-01)" fontSize="24px" onClick={goBack} />
        <p className="font-medium font-md color-white" style={{ flex: 1, textAlign: 'center' }}>
          {title}
        </p>
        <MdHome color="var(--white-01)" fontSize="24px" onClick={goTo(ROUTES.HOME)} />
      </div>
      <MainPageTransition>
        <div className={styles.content}>{children}</div>
      </MainPageTransition>
    </div>
  );
}

export default React.memo(WrapperLayout);
