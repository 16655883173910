/* eslint-disable @typescript-eslint/no-unused-vars */
import { MdAssignmentTurnedIn, MdCalendarMonth, MdDoorFront, MdPerson } from 'react-icons/md';
import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import TextButton from 'components/button/TextButton';
import InfoCard from 'components/card/InfoCard';
import Chip from 'components/common/Chip';
import { useTranslation } from 'react-i18next';
import { Divider, Skeleton } from 'antd-mobile';
import { getContractDetail } from 'services/user.service';
import { useParams } from 'react-router';
import { ContractInfoType } from 'types/user.type';
import { delayNavigate, formatDate, formatPrice, isSuccessCode, showData } from 'utils/common';
import { useDispatch, useSelector } from 'store/Store';
import { commonSelector, setContractDetailColor } from 'store/common';
import { CONTRACT_COLOR } from 'constants/color';
import { CONTRACT_STATUS } from 'constants/common';

export default function ContractDetailContainer() {
  const { t } = useTranslation();
  const { contractId } = useParams();
  const [contractDetail, setContractDetail] = useState<ContractInfoType>();
  const dispatch = useDispatch();
  const { contractDetailColor } = useSelector(commonSelector);
  const [isLoading, setIsLoading] = useState(false);

  const status = useMemo(() => {
    if (contractDetail?.isExpired) return CONTRACT_STATUS.EXPIRED;
    else return CONTRACT_STATUS.AVAILABLE;
  }, [contractDetail]);

  const btnColor = useMemo(() => {
    switch (contractDetailColor) {
      case CONTRACT_COLOR.AVAILABLE:
        return 'success';
      case CONTRACT_COLOR.EXPIRED:
        return 'danger';
      default:
        return 'primary';
    }
  }, [contractDetailColor]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await getContractDetail({
        contractId,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setContractDetail(data);
      }
      delayNavigate(() => {
        setIsLoading(false);
      });
    };
    getData();
  }, [contractId]);

  useEffect(() => {
    if (contractDetail?.isExpired) dispatch(setContractDetailColor(CONTRACT_COLOR.EXPIRED));
    else dispatch(setContractDetailColor(CONTRACT_COLOR.AVAILABLE));
  }, [contractDetail?.isExpired]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-xxl)',
              width: '100%',
            }}
          />
        ) : (
          <p className="color-white font-xxl font-bold">
            {showData(contractDetail?.infoRoom?.building.name)}
          </p>
        )}
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
              width: '100%',
            }}
          />
        ) : (
          <p className="color-white font-md font-light">
            {t('title.contract-code')}:
            <span className="font-medium"> #{showData(contractDetail?.id)}</span>
          </p>
        )}
      </div>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <InfoCard
            title={t('title.deposit-amount')}
            content={showData(
              contractDetail?.booking?.depositInvoices?.totalPrice
                ? formatPrice(Number(contractDetail?.booking?.depositInvoices?.totalPrice))
                : '',
            )}
            color={btnColor}
            Icon={MdAssignmentTurnedIn}
            isSkeleton={isLoading}
          />
          <div className={styles.divider}>
            <div
              className={styles.left}
              style={{
                background: contractDetailColor,
              }}
            />
            <Divider
              className="color-grey-02 font-xs font-medium"
              style={{
                width: '100%',
              }}
            >
              {t('title.detail-info')}
            </Divider>
            <div
              className={styles.right}
              style={{
                background: contractDetailColor,
              }}
            />
          </div>
          <InfoCard
            title={t('title.representative')}
            content={contractDetail?.signedUser?.fullName}
            color={btnColor}
            Icon={MdPerson}
            isSkeleton={isLoading}
          />
          <InfoCard
            title={t('title.room')}
            content={contractDetail?.infoRoom?.room.name}
            color={btnColor}
            Icon={MdDoorFront}
            isSkeleton={isLoading}
          />
          <InfoCard
            title={t('title.duration')}
            content={`${formatDate(contractDetail?.startAt)} ~ ${formatDate(
              contractDetail?.endAt,
            )}`}
            color={btnColor}
            Icon={MdCalendarMonth}
            isSkeleton={isLoading}
          />
        </div>
        <div className={styles.status}>
          <Chip color={btnColor} content={t(`title.${status}`)} isSkeleton={isLoading} />
        </div>
        {/* <div className={styles.groupBtn}>
          <TextButton title={t('button.save-as-pdf')} variant="outline" color={btnColor} />
        </div> */}
      </div>
    </div>
  );
}
