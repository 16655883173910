import React from 'react';
import styles from './styles.module.scss';
import { Outlet } from 'react-router';
import { MdArrowBack } from 'react-icons/md';
import useDirection from 'hooks/useDirection';

function SecondaryLayout() {
  const { goBack } = useDirection();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <MdArrowBack color="var(--white-01)" fontSize="24px" onClick={goBack} />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default React.memo(SecondaryLayout);
