/* eslint-disable @typescript-eslint/no-unused-vars */
import { Input, InputProps } from 'antd-mobile';
import styles from './styles.module.scss';
import React from 'react';
import { showData } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { bool } from 'joi';

type Props = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (newValue?: string) => void;
  errorMessage?: string;
  required?: boolean;
};
function CustomInput({
  title = '',
  placeholder = '',
  value = '',
  onChange,
  errorMessage = '',
  required = false,
  ...nest
}: Props & InputProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.form}>
      <p className={`font-medium font-md color-black ${required ? 'required' : ''}`}>
        {showData(title)}
      </p>
      <Input
        className={styles.input}
        placeholder={placeholder}
        clearable
        value={value}
        onChange={onChange}
        disabled={nest.disabled}
        {...nest}
      />
      {errorMessage && <p className="error-message">{t(`validate-message.${errorMessage}`)}</p>}
    </div>
  );
}

export default React.memo(CustomInput);
