import { Button, ButtonProps } from 'antd-mobile';
import React, { ComponentType } from 'react';
import styles from './styles.module.scss';
import { IconBaseProps } from 'react-icons';
import { showData } from 'utils/common';
import CustomBadge from '../CustomBadge';

type Props = {
  Icon: ComponentType<IconBaseProps>;
  title?: string;
  numberOfNotify?: number;
  disabled?: boolean;
};

function FunctionItem({
  Icon,
  title,
  numberOfNotify,
  disabled = false,
  ...nest
}: Props & ButtonProps) {
  return (
    <CustomBadge
      content={numberOfNotify ? String(numberOfNotify > 99 ? '+99' : numberOfNotify) : ''}
    >
      <Button className={styles.function} shape="default" disabled={disabled} {...nest}>
        <Icon fontSize="24px" />
        <p className="font-xs color-black font-medium text-overflow">{showData(title)}</p>
      </Button>
    </CustomBadge>
  );
}

export default React.memo(FunctionItem);
