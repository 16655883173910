import { CommonResType } from 'types/common.type';
import { AxiosGW } from './axios.service';

export const getNotificationsPaging = async (payload: CommonResType) => {
  const { limit, page } = payload;

  return AxiosGW.get(`/api/v1/notifications`, {
    params: { limit, page },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getNotificationDetail = async (payload: CommonResType) => {
  const { notificationId } = payload;

  return AxiosGW.get(`/api/v1/notification/${notificationId}/detail`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const readNotify = async (payload: CommonResType) => {
  const { notificationId } = payload;

  return AxiosGW.put(`/api/v1/notification/${notificationId}/read`, null, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const registerFcmToken = async (payload: { fcmToken: string }) => {
  const { fcmToken } = payload;

  return AxiosGW.post(
    `/api/v1/notification/fcm/register`,
    { fcmToken },
    {
      isDisableLoading: true,
      isDisableToast: true,
    },
  );
};

export const unRegisterFcmToken = async (payload: { fcmToken: string }) => {
  const { fcmToken } = payload;

  return AxiosGW.post(
    `/api/v1/notification/fcm/unregister`,
    { fcmToken },
    {
      isDisableLoading: true,
      isDisableToast: true,
    },
  );
};

export const countUnreadNotify = async () => {
  return AxiosGW.get(`/api/v1/notifications/unread/count`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const readAll = async () => {
  return AxiosGW.post(`/api/v1/notifications/read/all`, {
    isDisableLoading: true,
  });
};
