import React, { useState } from 'react';
import styles from './styles.module.scss';
import BottomPanel from 'components/panel/BottomPanel';
import { useTranslation } from 'react-i18next';
import { Image, Space } from 'antd-mobile';
import InputNumber from 'components/input/InputNumber';
import TextButton from 'components/button/TextButton';
import useDirection from 'hooks/useDirection';
import { sendOTP } from 'services/auth.service';
import useValidation from 'hooks/useValidation';
import { JOI } from 'utils/joi';
import { delayNavigate, getAppLogo, getAppName, isSuccessCode } from 'utils/common';
import { ROUTES } from 'constants/router';
import { useSelector } from 'store/Store';
import { commonSelector } from 'store/common';
import OpenAppOverlay from 'components/overlay/OpenAppOverlay';

type FormDataType = {
  phoneNumber: string;
};

type Props = {};

export default function LoginContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { isLoading } = useSelector(commonSelector);

  const [formData, setFormData] = useState<FormDataType>({
    phoneNumber: '',
  });
  const { errorMessage, onValidate } = useValidation<FormDataType>({
    phoneNumber: JOI.PHONE_REQUIRE,
  });

  const handleChangeForm = (key: string) => (newValue?: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleSendOTP = async () => {
    const isError = onValidate(formData);
    if (!isError) {
      const response = await sendOTP(formData);
      const { code } = response.data;
      if (isSuccessCode(code)) {
        delayNavigate(() =>
          goTo(ROUTES.VERIFY_OTP, {
            state: {
              ...formData,
            },
          })(),
        );
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <div
          style={{
            width: '100px',
            height: '100px',
            background: 'var(--white-01)',
            borderRadius: '100px',
            overflow: 'hidden',
            marginBottom: '24px',
            border: '2px solid var(--white-01)',
          }}
        >
          <Image
            src={getAppLogo()}
            alt="logo"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
          {getAppLogo()}
        </div>
        <p className={`font-xxxl color-white font-bold`}>{getAppName()}</p>
      </div>
      <BottomPanel anchors={[window.innerHeight * 0.6]}>
        <p className={`font-xxl font-bold color-black ${styles.title}`}>{t('title.login')}</p>

        <Space direction="vertical" block style={{ '--gap': '24px' }}>
          <InputNumber
            title={t('title.phone-number')}
            placeholder={t('placeholder.type-phone-number')}
            errorMessage={errorMessage.phoneNumber}
            value={formData.phoneNumber}
            onChange={handleChangeForm('phoneNumber')}
          />
          <TextButton
            title={t('button.sent-otp')}
            color="warning"
            onClick={handleSendOTP}
            loading={isLoading}
          />
        </Space>
      </BottomPanel>
      <OpenAppOverlay />
    </div>
  );
}
