import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { Skeleton, SpaceProps } from 'antd-mobile';
import { formatDateTime, showData } from 'utils/common';
import {
  MdAccessTimeFilled,
  MdBeenhere,
  MdBuild,
  MdCheckCircle,
  MdClear,
  MdOutlineContentPasteSearch,
} from 'react-icons/md';
import Chip from 'components/common/Chip';
import { MAINTENANCE_STATUS } from 'constants/common';
import { useTranslation } from 'react-i18next';

type Props = {
  status?: string;
  title?: string;
  createdAt?: string;
  isSkeleton?: boolean;
};

function MaintenanceCard({
  status,
  title,
  createdAt,
  isSkeleton = false,
  ...rest
}: Props & SpaceProps) {
  const { t } = useTranslation();

  const chipColor = useMemo(() => {
    switch (status) {
      case MAINTENANCE_STATUS.WAIT_FOR_APPROVAL:
        return 'default';
      case MAINTENANCE_STATUS.APPROVED:
        return 'primary';
      case MAINTENANCE_STATUS.IN_PROGRESS:
        return 'warning';
      case MAINTENANCE_STATUS.ACCEPTANCE:
        return 'purple';
      case MAINTENANCE_STATUS.COMPLETED:
        return 'success';
      case MAINTENANCE_STATUS.REJECTED:
        return 'danger';
      default:
        return 'default';
    }
  }, [status]);

  const color = useMemo(() => {
    switch (status) {
      case MAINTENANCE_STATUS.WAIT_FOR_APPROVAL:
        return 'var(--grey-03)';
      case MAINTENANCE_STATUS.APPROVED:
        return 'var(--blue)';
      case MAINTENANCE_STATUS.IN_PROGRESS:
        return 'var(--orange)';
      case MAINTENANCE_STATUS.ACCEPTANCE:
        return 'var(--purple)';
      case MAINTENANCE_STATUS.COMPLETED:
        return 'var(--green)';
      case MAINTENANCE_STATUS.REJECTED:
        return 'var(--red)';
      default:
        return 'var(--grey-03)';
    }
  }, [status]);

  const iconStatus = useMemo(() => {
    switch (status) {
      case MAINTENANCE_STATUS.WAIT_FOR_APPROVAL:
        return <MdAccessTimeFilled fontSize="30px" color="var(--white-01)" />;
      case MAINTENANCE_STATUS.APPROVED:
        return <MdCheckCircle fontSize="30px" color="var(--white-01)" />;
      case MAINTENANCE_STATUS.IN_PROGRESS:
        return <MdBuild fontSize="30px" color="var(--white-01)" />;
      case MAINTENANCE_STATUS.ACCEPTANCE:
        return <MdOutlineContentPasteSearch fontSize="30px" color="var(--white-01)" />;
      case MAINTENANCE_STATUS.COMPLETED:
        return <MdBeenhere fontSize="30px" color="var(--white-01)" />;
      case MAINTENANCE_STATUS.REJECTED:
        return <MdClear fontSize="30px" color="var(--white-01)" />;
      default:
        return <MdAccessTimeFilled fontSize="30px" color="var(--white-01)" />;
    }
  }, [status]);

  return (
    <div className={styles.wrapper} {...rest}>
      {isSkeleton ? (
        <Skeleton className={styles.iconBox} animated />
      ) : (
        <div
          className={styles.iconBox}
          style={{
            background: color,
          }}
        >
          {iconStatus}
        </div>
      )}
      <div className={styles.content}>
        {isSkeleton ? (
          <Skeleton
            style={{
              height: 'var(--font-md)',
            }}
            animated
          />
        ) : (
          <p className="font-medium font-md color-black">{showData(title)}</p>
        )}

        <div className={styles.group}>
          <Chip color={chipColor} content={t(`chip.${status}`)} isSkeleton={isSkeleton} />
        </div>
      </div>
      {isSkeleton ? (
        <Skeleton
          style={{
            height: 'var(--font-xs)',
            width: '40%',
            position: 'absolute',
            right: '12px',
            bottom: '12px',
          }}
          animated
        />
      ) : (
        <p
          className="font-light font-xs color-grey-01"
          style={{
            position: 'absolute',
            right: '12px',
            bottom: '12px',
          }}
        >
          {showData(formatDateTime(createdAt))}
        </p>
      )}
    </div>
  );
}

export default React.memo(MaintenanceCard);
