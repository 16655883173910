import React, { lazy } from 'react';

import { Navigate } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import { ROUTES } from 'constants/router';
import Loadable from 'components/loadable/Loadable';
import BlankLayout from 'layouts/BlankLayout';
import LoginPage from 'pages/LoginPage';
import SecondaryLayout from 'layouts/SecondaryLayout';
import VerifyOTPPage from 'pages/VerifyOTPPage';
import ProfilePage from 'pages/ProfilePage';
import EditProfilePage from 'pages/EditProfilePage';
import HelpPage from 'pages/HelpPage';
import TermConditionPage from 'pages/TermConditionPage';
import PrivacyPage from 'pages/PrivacyPage';
import ContactPage from 'pages/ContactPage';
import ContractPage from 'pages/ContractPage';
import ContractDetailPage from 'pages/ContractDetailPage';
import NotifyPage from 'pages/NotifyPage';
import NotifyDetailPage from 'pages/NotifyDetailPage';
import InvoicePage from 'pages/InvoicePage';
import InvoiceDetailPage from 'pages/InvoiceDetailPage';
import TransactionPage from 'pages/TransactionPage';
import TransactionDetailPage from 'pages/TransactionDetailPage';
import MaintenancePage from 'pages/MaintenancePage';
import MaintenanceDetailPage from 'pages/MaintenanceDetailPage';
import MaintenanceAddPage from 'pages/MaintenanceAddPage';

/* ***Layouts**** */
const MainLayout = Loadable(lazy(() => import('layouts/MainLayout')));

const Router = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Navigate to={ROUTES.HOME} /> },
      { path: ROUTES.HOME, exact: true, element: <HomePage /> },
    ],
  },

  {
    path: '/',
    element: <MainLayout hideHeader />,
    children: [{ path: ROUTES.PROFILE, exact: true, element: <ProfilePage /> }],
  },

  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: ROUTES.LOGIN, element: <LoginPage /> },

      { path: ROUTES.NOTIFY, element: <NotifyPage /> },
      { path: ROUTES.DETAIL_NOTIFY, element: <NotifyDetailPage /> },

      { path: ROUTES.EDIT_PROFILE, element: <EditProfilePage /> },
      { path: ROUTES.HELP_PROFILE, element: <HelpPage /> },
      { path: ROUTES.TERM_PROFILE, element: <TermConditionPage /> },
      { path: ROUTES.PRIVACY_PROFILE, element: <PrivacyPage /> },
      { path: ROUTES.CONTACT_PROFILE, element: <ContactPage /> },

      { path: ROUTES.CONTRACT, element: <ContractPage /> },
      { path: ROUTES.DETAIL_CONTRACT, element: <ContractDetailPage /> },

      { path: ROUTES.INVOICE, element: <InvoicePage /> },
      { path: ROUTES.DETAIL_INVOICE, element: <InvoiceDetailPage /> },

      { path: ROUTES.TRANSACTION, element: <TransactionPage /> },
      { path: ROUTES.DETAIL_TRANSACTION, element: <TransactionDetailPage /> },

      { path: ROUTES.MAINTENANCE, element: <MaintenancePage /> },
      { path: ROUTES.DETAIL_MAINTENANCE, element: <MaintenanceDetailPage /> },
      { path: ROUTES.ADD_MAINTENANCE, element: <MaintenanceAddPage /> },
    ],
  },

  {
    path: '/',
    element: <SecondaryLayout />,
    children: [{ path: ROUTES.VERIFY_OTP, element: <VerifyOTPPage /> }],
  },
];

export default Router;
