import { Button, ButtonProps } from 'antd-mobile';
import styles from './styles.module.scss';
import React from 'react';
import { showData } from 'utils/common';
import { ColorType } from '@/types/common.type';

type Props = {
  title?: string;
  color?: ColorType;
  variant?: 'none' | 'solid' | 'outline' | undefined;
};

function TextButton({ title, color, variant = 'solid', ...nest }: Props & ButtonProps) {
  return (
    <Button
      className={`font-bold font-md font-white ${styles.button}`}
      color={color}
      fill={variant}
      {...nest}
    >
      {showData(title)}
    </Button>
  );
}

export default React.memo(TextButton);
