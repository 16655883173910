import React, { useRef, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import styles from './styles.module.scss';
import { Avatar, Skeleton } from 'antd-mobile';
import IconButton from 'components/button/IconButton';
import { MdCameraAlt, MdClear } from 'react-icons/md';
import TextButton from 'components/button/TextButton';
import { useTranslation } from 'react-i18next';
import { getAvatarUrl } from 'utils/common';

type Props = {
  onUpload?: (base64: string) => Promise<boolean>;
};

const AvatarUpload = ({ onUpload }: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const inputRef = useRef<HTMLInputElement>(null);
  const cropArea = useRef<Area>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [croppedImg, setCroppedImg] = useState('');
  const [isLoadAvatar, setIsLoadAvatar] = useState(true);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    cropArea.current = croppedAreaPixels;
  };

  const handleChooseFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleCloseCrop = () => {
    setImage(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  };

  const handleCropImage = async () => {
    if (inputRef.current?.files && inputRef.current.files[0]) {
      setIsLoading(true);
      const imageBase64 = await cropAndConvertToBase64(
        inputRef.current?.files[0],
        cropArea.current.x,
        cropArea.current.y,
        cropArea.current.width,
        cropArea.current.height,
      );
      const isSuccess = onUpload ? await onUpload(String(imageBase64)) : false;
      isSuccess && setCroppedImg(String(imageBase64));
      setIsLoading(false);
      handleCloseCrop();
    }
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function () {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageLoaded = () => {
    setIsLoadAvatar(false);
  };

  const cropAndConvertToBase64 = (
    file: Blob,
    x: number,
    y: number,
    width: number,
    height: number,
  ) => {
    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = function (event: ProgressEvent<FileReader>) {
        img.src = event.target ? String(event.target.result) : '';

        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = width;
          canvas.height = height;

          ctx?.drawImage(img, -x, -y);

          const base64 = canvas.toDataURL('image/jpeg');

          resolve(base64);
        };
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <div className={styles.avatarWrapper}>
      {isLoadAvatar && (
        <Skeleton
          animated
          style={{
            width: '60px',
            height: '60px',
            borderRadius: '30px',
          }}
        />
      )}
      <Avatar
        src={croppedImg ? croppedImg : getAvatarUrl()}
        style={{
          '--border-radius': '60px',
          '--size': '100px',
          display: isLoadAvatar ? 'none' : 'block',
        }}
        onLoad={onImageLoaded}
      />
      <IconButton
        className={styles.icon}
        Icon={MdCameraAlt}
        style={{
          background: 'var(--orange)',
        }}
        onClick={handleChooseFile}
      />
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{
          display: 'none',
        }}
        onChange={handleChangeImage}
      />
      {image && (
        <div className={styles.cropImage}>
          <MdClear
            className={styles.closeIcon}
            color="var(--white-01)"
            fontSize="32px"
            onClick={handleCloseCrop}
          />
          <Cropper
            image={String(image)}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            cropSize={{
              width: window.innerWidth * 0.8,
              height: window.innerWidth * 0.8,
            }}
            style={{}}
          />
          <TextButton
            className={styles.confirmButton}
            title={t('button.upload-avatar')}
            color="warning"
            onClick={handleCropImage}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(AvatarUpload);
