import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import NotifyDetailContainer from 'containers/NotifyDetailContainer';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function NotifyDetailPage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.notify-detail')}>
      <NotifyDetailContainer />
    </WrapperLayout>
  );
}
