import React from 'react';
import styles from './styles.module.scss';
import { FloatingPanel, FloatingPanelProps } from 'antd-mobile';

type Props = {
  children: React.ReactNode;
  anchors: number[];
  refPanel?: any;
};

function BottomPanel({ children, anchors = [], refPanel, ...nest }: Props & FloatingPanelProps) {
  return (
    <FloatingPanel
      ref={refPanel}
      anchors={anchors}
      handleDraggingOfContent={false}
      className={styles.panel}
      {...nest}
    >
      {children}
    </FloatingPanel>
  );
}

export default React.memo(BottomPanel);
