/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Avatar, Dialog, Image, Skeleton, Switch } from 'antd-mobile';
import { initReactI18next, useTranslation } from 'react-i18next';
import SettingItem from 'components/common/SettingItem';
import {
  MdCheck,
  MdClear,
  MdContacts,
  MdDoDisturb,
  MdDoorbell,
  MdHelp,
  MdLanguage,
  MdLibraryBooks,
  MdLockPerson,
  MdNotifications,
} from 'react-icons/md';
import TextButton from 'components/button/TextButton';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import { concatFullName, delayNavigate, getAvatarUrl, getVersion, showData } from 'utils/common';
import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';
import { AccountDetailType } from 'types/auth.type';
import { createPortal } from 'react-dom';
import { isAllowNotify, requestNotifyPermission } from 'externals/firebase';
import { useDispatch, useSelector } from 'store/Store';
import { commonSelector, setLanguage } from 'store/common';
import { unRegisterFcmToken } from 'services/notify.service';
import ConfirmModal from 'components/modal/ConfirmModal';
import useDisclosure from 'hooks/useDisclosure';

type Props = {};

const Storage = new LocalStorage();

const SETTINGS = [
  {
    id: 2,
    icon: MdHelp,
    title: 'title.help',
    path: ROUTES.HELP_PROFILE,
  },
  {
    id: 3,
    icon: MdLibraryBooks,
    title: 'title.term-condition',
    path: ROUTES.TERM_PROFILE,
  },
  {
    id: 4,
    icon: MdLockPerson,
    title: 'title.privacy',
    path: ROUTES.PRIVACY_PROFILE,
  },
  {
    id: 5,
    icon: MdContacts,
    title: 'title.contact',
    path: ROUTES.CONTACT_PROFILE,
  },
];

export default function ProfileContainer({}: Props) {
  const { t, i18n } = useTranslation();
  const { goTo } = useDirection();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAllow, setIsAllow] = useState(isAllowNotify());
  const [isLoadAvatar, setIsLoadAvatar] = useState(true);
  const { language } = useSelector(commonSelector);
  const dispatch = useDispatch();
  const accountDetail: AccountDetailType = Storage.getStorageItem(
    STORAGE_KEY.ACCOUNT_INFO,
  )?.accountDetail;

  const handleLogout = async () => {
    const fcmToken = Storage.getStorageItem(STORAGE_KEY.NOTIFY_TOKEN);
    if (fcmToken) {
      await unRegisterFcmToken({
        fcmToken,
      });
    }
    window.location.href = ROUTES.LOGIN;
    Storage.clearAllStorage();
  };

  const handleAllowNotify = async () => {
    const permission = await requestNotifyPermission();
    setIsAllow(permission === 'granted');
  };

  const handleChangeLanguage = (checked: boolean) => {
    dispatch(setLanguage(checked ? 'vi' : 'en'));
    i18n.changeLanguage(checked ? 'vi' : 'en');
  };

  const onImageLoaded = () => {
    setIsLoadAvatar(false);
  };

  useEffect(() => {
    setIsAllow(isAllowNotify());
  }, []);

  return (
    <div className={styles.wrapper}>
      {createPortal(
        <div className="avatar-wrapper" onClick={goTo(ROUTES.EDIT_PROFILE)}>
          {isLoadAvatar && (
            <Skeleton
              animated
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '30px',
              }}
            />
          )}
          <Avatar
            src={getAvatarUrl()}
            onLoad={onImageLoaded}
            style={{
              '--border-radius': '60px',
              '--size': '60px',
              display: isLoadAvatar ? 'none' : 'block',
            }}
          />
          <div className="content">
            <p className="font-lg font-bold color-black">
              {concatFullName(accountDetail?.firstName, accountDetail?.lastName)}
            </p>
            <p className="font-sm font-light color-grey-01">{t('paragraph.touch-change')}</p>
          </div>
        </div>,
        document.getElementById('header-content') || document.body,
      )}
      <div className={styles.settingList}>
        <p
          className="font-bold font-xl color-black"
          style={{
            marginBottom: '24px',
          }}
        >
          {t('title.app-setting')}
        </p>
        <div className={styles.listItem}>
          <div className={styles.switchAction}>
            <MdNotifications fontSize="24px" color="var(--orange)" />
            <p
              className="font-light color-black font-md"
              style={{
                flex: 1,
                textAlign: 'left',
              }}
            >
              {t('title.notify')}
            </p>
            <Switch
              checked={isAllow}
              checkedText={<MdCheck fontSize={18} />}
              uncheckedText={<MdClear fontSize={18} />}
              onChange={handleAllowNotify}
              style={{
                '--checked-color': 'var(--blue)',
              }}
            />
          </div>
          <div className={styles.switchAction}>
            <MdLanguage fontSize="24px" color="var(--orange)" />
            <p
              className="font-light color-black font-md"
              style={{
                flex: 1,
                textAlign: 'left',
              }}
            >
              {t('title.language')}
            </p>
            <Switch
              checked={language === 'vi'}
              onChange={handleChangeLanguage}
              checkedText={<Image src="/images/icons/png/vi-flag.png" width="20px" />}
              uncheckedText={<Image src="/images/icons/png/uk-flag.png" width="20px" />}
              style={{
                '--checked-color': 'var(--blue)',
              }}
            />
          </div>
        </div>

        <p
          className="font-bold font-xl color-black"
          style={{
            marginBottom: '24px',
            marginTop: '24px',
          }}
        >
          {t('title.app-information')}
        </p>
        <div className={styles.listItem}>
          {SETTINGS.map((setting) => {
            return (
              <SettingItem
                key={setting.id}
                Icon={setting.icon}
                title={t(setting.title)}
                onClick={
                  setting.path
                    ? goTo(setting.path)
                    : () => {
                        return;
                      }
                }
              />
            );
          })}
          <TextButton
            title={t('button.logout')}
            color="danger"
            style={{ marginTop: '12px' }}
            onClick={onOpen}
          />
          <div
            style={{
              display: 'flex',
              paddingBottom: '100px',
            }}
          ></div>
          <p className="color-black" style={{ textAlign: 'center', padding: '32px 0' }}>
            Version: {showData(getVersion())}
          </p>
        </div>
      </div>
      <ConfirmModal
        confirmTitle={t('paragraph.logout')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleLogout}
      />
    </div>
  );
}
