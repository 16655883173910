/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { MdAssignment, MdBuild, MdCurrencyExchange, MdFeed } from 'react-icons/md';
import FunctionItem from 'components/common/FunctionItem';
import { useTranslation } from 'react-i18next';
import ContractCard from 'components/card/ContractCard';
import CardSwiper from 'components/common/CardSwiper';
import InvoiceCard from 'components/card/InvoiceCard';
import { ROUTES } from 'constants/router';
import useDirection from 'hooks/useDirection';
import { CONTRACT_STATUS, DELAY_TRANSITION } from 'constants/common';
import {
  countInvoicesDebit,
  getAvailableContracts,
  getDepositInvoices,
} from 'services/user.service';
import { useDispatch, useSelector } from 'store/Store';
import { propertySelector } from 'store/property';
import { formatDate, isSuccessCode } from 'utils/common';
import { ContractInfoType, InvoiceInfoType } from 'types/user.type';
import { ContractType } from 'types/common.type';
import EmptyCard from 'components/card/EmptyCard';
import { countUnreadNotify } from 'services/notify.service';
import { commonSelector, setCountInfo } from 'store/common';
import { createPortal } from 'react-dom';
import { getBranchDetail } from 'services/property.service';
import { BranchInfoType } from '@/types/general.type';

type Props = {};

export default function HomeContainer({}: Props) {
  const { t } = useTranslation();
  const { currentBuilding } = useSelector(propertySelector);
  const { countInfo } = useSelector(commonSelector);
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const [listContract, setListContract] = useState<ContractInfoType[]>([]);
  const [listInvoice, setListInvoice] = useState<InvoiceInfoType[]>([]);
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);

  const calculateContractStatus = (isExpired?: boolean) => {
    if (isExpired) return CONTRACT_STATUS.EXPIRED;
    else return CONTRACT_STATUS.AVAILABLE;
  };

  const FUNCTIONS = useMemo(() => {
    return [
      {
        title: 'title.invoice',
        icon: MdFeed,
        numberOfNotify: countInfo.debitInvoice,
        path: ROUTES.INVOICE,
      },
      {
        title: 'title.transaction',
        icon: MdCurrencyExchange,
        numberOfNotify: 0,
        path: ROUTES.TRANSACTION,
      },
      {
        title: 'title.maintenance',
        icon: MdBuild,
        numberOfNotify: 0,
        path: ROUTES.MAINTENANCE,
      },
      {
        title: 'title.contract',
        icon: MdAssignment,
        numberOfNotify: 0,
        path: ROUTES.CONTRACT,
      },
    ];
  }, [countInfo.debitInvoice]);

  useEffect(() => {
    const getData = async () => {
      if (currentBuilding?.id) {
        setIsLoadingContract(true);
        const response = await getAvailableContracts({
          buildingId: currentBuilding?.id,
        });
        const { data, code } = response.data;
        if (isSuccessCode(code)) {
          setListContract(data as ContractInfoType[]);
        } else {
          setListContract([]);
        }
        setTimeout(() => {
          setIsLoadingContract(false);
        }, DELAY_TRANSITION);
      }
    };
    getData();
  }, [currentBuilding?.id]);

  useEffect(() => {
    const getData = async () => {
      if (currentBuilding?.id) {
        setIsLoadingInvoice(true);
        const response = await getDepositInvoices({
          buildingId: currentBuilding?.id,
        });
        const { data, code } = response.data;

        if (isSuccessCode(code)) {
          setListInvoice(data as InvoiceInfoType[]);
        } else {
          setListInvoice([]);
        }
        setTimeout(() => {
          setIsLoadingInvoice(false);
        }, DELAY_TRANSITION);
      }
    };
    getData();
  }, [currentBuilding?.id]);

  useEffect(() => {
    const getData = async () => {
      if (currentBuilding?.id) {
        const [invoice, notify] = await Promise.all([
          countInvoicesDebit({
            buildingId: currentBuilding?.id,
          }),
          countUnreadNotify(),
        ]);
        dispatch(
          setCountInfo({
            debitInvoice: invoice?.data?.data?.total,
            notify: notify?.data?.data?.total,
          }),
        );
      }
    };
    getData();
  }, [currentBuilding?.id]);

  useEffect(() => {
    const getDetailTriggerZalo = async () => {
      const response = await getBranchDetail();
      const { code, data } = response.data;
      if (isSuccessCode(code)) {
        const { zaloId } = data as BranchInfoType;
        if (zaloId) {
          const block = document.getElementById('zalo-chat-widget');
          block?.setAttribute('data-oaid', String(zaloId));
          const existed = document.getElementById('zalo-script');
          if (!existed) {
            const script = document.createElement('script');
            script.src = 'https://sp.zalo.me/plugins/sdk.js';
            script.id = 'zalo-script';
            document.body.appendChild(script);
          }
        }
      }
    };
    getDetailTriggerZalo();
  }, []);

  return (
    <div className={styles.wrapper}>
      {createPortal(
        <div className="function-wrapper">
          {FUNCTIONS?.map((func, idx) => {
            return (
              <FunctionItem
                key={idx}
                title={t(func.title)}
                Icon={func.icon}
                numberOfNotify={func.numberOfNotify}
                onClick={
                  func.path
                    ? goTo(func.path)
                    : () => {
                        return;
                      }
                }
              />
            );
          })}
        </div>,
        document.getElementById('header-content') || document.body,
      )}
      <div className={styles.contract}>
        <p className={`font-bold font-xl color-black ${styles.title}`}>
          {t('title.current-contract')}
        </p>
        <div className={styles.listItem}>
          <CardSwiper
            items={
              isLoadingContract
                ? [<ContractCard isSkeleton isHideChip />]
                : listContract?.map((contract) => {
                    return (
                      <ContractCard
                        key={contract.id}
                        contractCode={String(contract.id)}
                        expiredDate={formatDate(contract.endAt)}
                        room={contract.infoRoom?.room.name}
                        isHideChip
                        onClick={goTo(
                          ROUTES.DETAIL_CONTRACT.replace(':contractId', String(contract.id)),
                        )}
                        status={calculateContractStatus(contract.isExpired) as ContractType}
                      />
                    );
                  })
            }
            emptyElement={
              <EmptyCard
                Icon={MdAssignment}
                text={t('paragraph.no-contract')}
                style={{
                  height: '56px',
                }}
              />
            }
          />
        </div>
      </div>
      <div className={styles.transaction}>
        <p className={`font-bold font-xl color-black ${styles.title}`}>
          {t('title.unpaid-invoice')}
        </p>
        <div className={styles.listItem}>
          <CardSwiper
            items={
              isLoadingInvoice
                ? [<InvoiceCard isSkeleton />]
                : listInvoice?.map((invoice) => {
                    return (
                      <InvoiceCard
                        key={invoice.id}
                        status={invoice.status}
                        invoiceCode={String(invoice.id)}
                        period={invoice.contractPeriod?.name}
                        totalPrice={Number(invoice.totalPrice)}
                        createdAt={invoice.createdAt}
                        isHideChip
                        onClick={goTo(
                          ROUTES.DETAIL_INVOICE.replace(':invoiceId', String(invoice.id)),
                        )}
                      />
                    );
                  })
            }
            emptyElement={
              <EmptyCard
                Icon={MdFeed}
                text={t('paragraph.no-invoice')}
                style={{
                  height: '56px',
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
