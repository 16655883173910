import { REGEX } from 'constants/regex';
import Joi from 'joi';

export const JOI = {
  PHONE: Joi.string().regex(REGEX.PHONE).messages({ 'string.pattern.base': 'invalid-phone' }),
  PHONE_REQUIRE: Joi.string()
    .required()
    .messages({ 'string.empty': 'required-phone' })
    .regex(REGEX.PHONE)
    .messages({ 'string.pattern.base': 'invalid-phone' }),
  PASSWORD: Joi.string()
    .required()
    .regex(REGEX.PASSWORD)
    .messages({ 'string.pattern.base': 'invalid-password' }),
  REQUIRE: Joi.string().required().max(30).messages({ 'string.max': 'invalid-text' }),
  REQUIRE_ARRAY: Joi.array().items().required().min(1).messages({ 'array.min': 'min-building' }),
  PRICE: Joi.string().required().min(1).messages({ 'string.min': 'invalid-price' }),
  NUMBER: Joi.number().required().min(1).messages({
    'number.min': 'min-number',
    'number.base': 'invalid-number-type',
    'number.unsafe': 'invalid-number',
  }),
  ALLOW: Joi.any(),
  EMAIL: Joi.string()
    .email({
      tlds: {
        allow: false,
      },
    })
    .allow(null, '')
    .message('invalid-email'),
};

export const CUSTOM_JOI_MESSAGE = {
  'string.empty': 'required',
};

export class JoiValidate<T> {
  private schema: Joi.ObjectSchema<T>;

  constructor(obj: Joi.PartialSchemaMap<T>) {
    this.schema = Joi.object().options({ abortEarly: false }).keys(obj);
  }

  validateSchema(payload: T) {
    const res = this.schema.validate(payload, { messages: CUSTOM_JOI_MESSAGE });
    const keys = Object.keys(payload as unknown as Record<string, any>);
    const errorMessage: Record<string, string> = {};
    keys.forEach((key) => {
      errorMessage[key] = '';
    });
    if (res.error) {
      res.error.details.forEach((error) => {
        errorMessage[String(error.context?.key)] = error.message;
      });

      return {
        isError: true,
        errorMessage: errorMessage as { [key in keyof T]: string },
      };
    }

    return {
      isError: false,
      errorMessage: errorMessage as { [key in keyof T]: string },
    };
  }
}
