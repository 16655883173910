import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';
import ComingSoonContainer from 'containers/ComingSoonContainer';

type Props = {};

export default function PrivacyPage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.privacy')}>
      <ComingSoonContainer />
    </WrapperLayout>
  );
}
