import { NavigateOptions, useNavigate } from 'react-router';

function useDirection() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goTo = (destination: string, config?: NavigateOptions) => () => {
    navigate(destination, config);
  };

  const goToNewTab = (destination: string) => () => {
    const newWindow = window.open(destination, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const goToReplace = (destination: string) => {
    navigate(destination, { replace: true });
  };

  const goBackByTime = (time: number) => {
    navigate(time * -1);
  };

  return { goBack, goTo, goToReplace, goBackByTime, goToNewTab };
}

export default useDirection;
