import axios, { AxiosError } from 'axios';
import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';
import { dispatch } from 'store/Store';
import { setLoading, showToast } from 'store/common';
import { DELAY_TRANSITION } from 'constants/common';
import { isSuccessCode } from 'utils/common';
import { ROUTES } from 'constants/router';

const storage = new LocalStorage();

/** NOTE: APP GATEWAY AXIOS */
export const AxiosGW = axios.create({
  baseURL: process.env.REACT_APP_MOBILE_GATEWAY, // Replace with your API base URL
});

// Request interceptor e
AxiosGW.interceptors.request.use(
  (config) => {
    const { isDisableLoading } = config;
    const accessToken = storage.getStorageItem(STORAGE_KEY.ACCESS_TOKEN);

    if (!isDisableLoading) {
      dispatch(setLoading(true));
    }

    if (accessToken) {
      if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    throw error;
  },
);

// Response interceptor
AxiosGW.interceptors.response.use(
  (response) => {
    const { isDisableToast } = response.config;

    if (!isDisableToast) {
      const code =
        response.data?.code == 4 ? response?.data?.description?.code : response.data?.code;
      dispatch(
        showToast({
          code: code,
          isError: !isSuccessCode(code),
        }),
      );
    }

    setTimeout(() => {
      dispatch(setLoading(false));
    }, DELAY_TRANSITION);

    return response;
  },
  (error: AxiosError) => {
    const { response } = error;
    if (response) {
      const code =
        (response.data as any)?.code == 4
          ? (response.data as any)?.description?.code
          : (response.data as any)?.code;
      dispatch(
        showToast({
          code: code,
          isError: !isSuccessCode(code),
        }),
      );
    }
    setTimeout(() => {
      dispatch(setLoading(false));
    }, DELAY_TRANSITION);

    if (response?.status === 401) {
      storage.clearAllStorage();
      window.location.href = ROUTES.LOGIN;
    }

    return response;
  },
);
