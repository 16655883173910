import {
  MdAccessTimeFilled,
  MdCurrencyExchange,
  MdFeed,
  MdPayment,
  MdPerson,
} from 'react-icons/md';
import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import InfoCard from 'components/card/InfoCard';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd-mobile';
import { delayNavigate, formatDateTime, formatPrice, isSuccessCode, showData } from 'utils/common';
import { dispatch, useSelector } from 'store/Store';
import { commonSelector, setTransactionDetailColor } from 'store/common';
import { TRANSACTION_COLOR } from 'constants/color';
import { getTransactionDetail } from 'services/user.service';
import { useParams } from 'react-router';
import { ContractFeeTransaction } from 'types/user.type';
import { ROUTES } from 'constants/router';

export default function TransactionDetailContainer() {
  const { t } = useTranslation();
  const { transactionId } = useParams();
  const { transactionDetailColor } = useSelector(commonSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState<ContractFeeTransaction>();

  const btnColor = useMemo(() => {
    switch (transactionDetailColor) {
      case 'var(--green)':
        return 'success';
      case 'var(--soft-red)':
        return 'danger';
    }
  }, [transactionDetailColor]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await getTransactionDetail({
        transactionId,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setTransactionDetail(data);
      }
      delayNavigate(() => {
        setIsLoading(false);
      });
    };
    getData();
  }, [transactionId]);

  useEffect(() => {
    if (transactionDetail?.transactionType)
      dispatch(
        setTransactionDetailColor(
          TRANSACTION_COLOR[
            transactionDetail?.transactionType.type as keyof typeof TRANSACTION_COLOR
          ],
        ),
      );
  }, [transactionDetail]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-xxl)',
              width: '100%',
            }}
          />
        ) : (
          <p className="color-white font-xxl font-bold">
            {showData(transactionDetail?.transactionType?.nameVi)}
          </p>
        )}
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
              width: '100%',
            }}
          />
        ) : (
          <p className="color-white font-md font-light">
            {t('title.transaction-code')}:
            <span className="font-medium">#{showData(transactionDetail?.id)}</span>
          </p>
        )}
      </div>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <InfoCard
            title={t('title.transaction-amount')}
            content={showData(formatPrice(Number(transactionDetail?.transactionAmount)))}
            color={btnColor}
            Icon={MdCurrencyExchange}
            isSkeleton={isLoading}
          />

          <InfoCard
            title={t('title.payment-method')}
            content={showData(transactionDetail?.paymentMethod?.name)}
            color={btnColor}
            Icon={MdPayment}
            isSkeleton={isLoading}
          />

          <InfoCard
            title={t('title.invoice')}
            content={`${showData(transactionDetail?.invoice?.name)} (#${showData(
              transactionDetail?.invoiceId,
            )})`}
            color={btnColor}
            Icon={MdFeed}
            isSkeleton={isLoading}
            link={ROUTES.DETAIL_INVOICE.replace(':invoiceId', String(transactionDetail?.invoiceId))}
          />
          <InfoCard
            title={t('title.transaction-time')}
            content={showData(formatDateTime(transactionDetail?.createdAt))}
            color={btnColor}
            Icon={MdAccessTimeFilled}
            isSkeleton={isLoading}
          />
          <InfoCard
            title={t('title.transaction-creator')}
            content={showData(transactionDetail?.paidUser?.fullName)}
            color={btnColor}
            Icon={MdPerson}
            isSkeleton={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
