import { CommonResType } from 'types/common.type';
import { AxiosGW } from './axios.service';
import { ALL_OPTION } from 'constants/common';

export const getAvailableContracts = async (payload: CommonResType) => {
  const { buildingId } = payload;

  return AxiosGW.get(`/api/v1/user/building/${buildingId}/contracts`, {
    params: {
      isFull: true,
      isExpired: false,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getContractsPaging = async (payload: CommonResType) => {
  const { buildingId, limit, page } = payload;

  return AxiosGW.get(`/api/v1/user/building/${buildingId}/contracts`, {
    params: { limit, page },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getContractDetail = async (payload: CommonResType) => {
  const { contractId } = payload;

  return AxiosGW.get(`/api/v1/user/contract/${contractId}/detail`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getDepositInvoices = async (payload: CommonResType) => {
  const { buildingId } = payload;

  return AxiosGW.get(`/api/v1/user/building/${buildingId}/invoices`, {
    params: {
      isFull: true,
      isDebit: true,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getInvoicesPaging = async (payload: CommonResType & { invoiceStatus: string }) => {
  const { buildingId, limit, page, invoiceStatus } = payload;

  return AxiosGW.get(`/api/v1/user/building/${buildingId}/invoices`, {
    params: {
      limit,
      page,
      ...(invoiceStatus === ALL_OPTION ? {} : { invoiceStatus }),
      sort: 'createdAt',
      dir: 'desc',
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getInvoiceDetail = async (payload: CommonResType) => {
  const { invoiceId } = payload;

  return AxiosGW.get(`/api/v1/user/invoice/${invoiceId}/detail`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const countInvoicesDebit = async (payload: CommonResType) => {
  const { buildingId } = payload;

  return AxiosGW.get(`/api/v1/user/building/${buildingId}/invoices/debit/count`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getTransactionsPaging = async (payload: CommonResType) => {
  const { buildingId, limit, page } = payload;

  return AxiosGW.get(`/api/v1/user/building/${buildingId}/transactions`, {
    params: { limit, page },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getTransactionDetail = async (payload: CommonResType) => {
  const { transactionId } = payload;

  return AxiosGW.get(`/api/v1/user/transaction/${transactionId}/detail`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};
