import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { MAINTENANCE_STATUS } from 'constants/common';
import { useTranslation } from 'react-i18next';
import Chip from 'components/common/Chip';
import { getDetailRequestMaintenance } from 'services/kanban.service';
import { useParams } from 'react-router';
import { delayNavigate, isSuccessCode, showData } from 'utils/common';
import { MaintenanceRequestType } from 'types/kanban.type';
import { Skeleton } from 'antd-mobile';
import ImageCard from 'components/card/ImageCard';
import MaintainStatusHistoryCard from 'components/card/MaintainStatusHistoryCard';
import BottomSpace from 'components/common/BottomSpace';
import EmptyCard from 'components/card/EmptyCard';

type Props = {};

export default function MaintenanceDetailContainer({}: Props) {
  const { t } = useTranslation();
  const [requestDetail, setRequestDetail] = useState<MaintenanceRequestType>();
  const [isLoading, setIsLoading] = useState(false);
  const { requestId } = useParams();

  const getDetailRequest = async () => {
    setIsLoading(true);
    const response = await getDetailRequestMaintenance({
      taskId: requestId,
    });
    const { data, code } = response.data;
    if (isSuccessCode(code)) {
      setRequestDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const chipColor = useMemo(() => {
    switch (requestDetail?.tab?.name) {
      case MAINTENANCE_STATUS.WAIT_FOR_APPROVAL:
        return 'default';
      case MAINTENANCE_STATUS.APPROVED:
        return 'primary';
      case MAINTENANCE_STATUS.IN_PROGRESS:
        return 'warning';
      case MAINTENANCE_STATUS.ACCEPTANCE:
        return 'purple';
      case MAINTENANCE_STATUS.COMPLETED:
        return 'success';
      case MAINTENANCE_STATUS.REJECTED:
        return 'danger';
      default:
        return 'default';
    }
  }, [requestDetail]);

  useEffect(() => {
    getDetailRequest();
  }, [requestId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        {isLoading ? (
          <Skeleton animated style={{ width: '100%', height: '16px' }} />
        ) : (
          <div className={styles.fieldInfo}>
            <p className="font-md color-back font-bold">{t('title.title')}: </p>
            <p className="font-md color-back font-light">{showData(requestDetail?.name)}</p>
          </div>
        )}
        {isLoading ? (
          <Skeleton animated style={{ width: '100%', height: '16px' }} />
        ) : (
          <div className={styles.noteStatus}>
            <p className="font-md color-back font-bold">{t('title.status')}: </p>
            <Chip color={chipColor} content={t(`chip.${requestDetail?.tab?.name}`)} />
          </div>
        )}
        {isLoading ? (
          <>
            <Skeleton animated style={{ width: '100%', height: '16px' }} />
            <Skeleton animated style={{ width: '100%', height: '16px' }} />
            <Skeleton animated style={{ width: '100%', height: '16px' }} />
          </>
        ) : (
          <div className={styles.noteInfo}>
            <p className="font-md color-back font-bold">{t('title.note')}: </p>
            <p
              className="font-md color-back font-light"
              dangerouslySetInnerHTML={{
                __html: showData(requestDetail?.description),
              }}
            ></p>
          </div>
        )}
      </div>
      <p className="font-md color-back font-bold">{t('title.attachment')}: </p>
      {
        <div className={styles.listImage}>
          {isLoading ? (
            [1, 2, 3, 4].map((image) => {
              return (
                <Skeleton
                  key={image}
                  animated
                  style={{
                    width: (window.innerWidth - 12 * 4) / 3,
                    height: (window.innerWidth - 12 * 4) / 3,
                  }}
                />
              );
            })
          ) : requestDetail?.taskImages && requestDetail?.taskImages?.length > 0 ? (
            requestDetail?.taskImages.map((image) => {
              return (
                <ImageCard
                  key={image.id}
                  className={styles.imageBox}
                  url={image.imageUrl}
                  style={{
                    width: (window.innerWidth - 12 * 4) / 3,
                    height: (window.innerWidth - 12 * 4) / 3,
                  }}
                />
              );
            })
          ) : (
            <EmptyCard
              text={t('title.no-attachment')}
              style={{
                width: '100%',
                height: (window.innerWidth - 12 * 4) / 3 - 24,
              }}
            />
          )}
        </div>
      }
      <div className={styles.history}>
        <p className="font-md color-back font-bold">{t('title.status-history')}: </p>
        <div className={styles.historyList}>
          {isLoading ? (
            [1, 2, 3, 4].map((image) => {
              return <MaintainStatusHistoryCard key={image} isSkeleton />;
            })
          ) : requestDetail?.historyTasks && requestDetail?.historyTasks.length > 0 ? (
            requestDetail?.historyTasks?.map((history) => {
              return (
                <MaintainStatusHistoryCard
                  key={history.id}
                  name={history?.accountDetail?.fullName}
                  createdAt={history.createdAt}
                  sourceStatus={history?.sourceTab?.name}
                  desStatus={history?.destinationTab?.name}
                />
              );
            })
          ) : (
            <EmptyCard
              text={t('title.no-history')}
              style={{
                width: 'auto',
                height: '66px',
              }}
            />
          )}
        </div>
      </div>
      <BottomSpace />
    </div>
  );
}
