import { AxiosGW } from './axios.service';

export const getAppVersion = async () => {
  return AxiosGW.get('/api/v1/general/setting/version', {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getMaintenanceTasks = async () => {
  return AxiosGW.get('/api/v1/general/task/title', {
    isDisableLoading: true,
    isDisableToast: true,
  });
};
