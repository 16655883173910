import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import ContractDetailContainer from 'containers/ContractDetailContainer';
import { useSelector } from 'store/Store';
import { commonSelector } from 'store/common';

type Props = {};

export default function ContractDetailPage({}: Props) {
  const { contractDetailColor } = useSelector(commonSelector);

  return (
    <WrapperLayout
      title={''}
      headerColor={contractDetailColor}
      style={{
        background: contractDetailColor,
      }}
    >
      <ContractDetailContainer />
    </WrapperLayout>
  );
}
