import { ColorType } from '@/types/common.type';
import { Badge, BadgeProps, Skeleton } from 'antd-mobile';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

type Props = {
  content?: string;
  color?: ColorType;
  isSkeleton?: boolean;
};

function Chip({ content, color, isSkeleton = false, ...nest }: Props & BadgeProps) {
  const mainColor = useMemo(() => {
    switch (color) {
      case 'success':
        return 'var(--green)';
      case 'warning':
        return 'var(--orange)';
      case 'danger':
        return 'var(--red)';
      case 'primary':
        return 'var(--blue)';
      case 'default':
        return 'var(--grey-03)';
      case 'purple':
        return 'var(--purple)';
    }
  }, [color]);

  return (
    <>
      {content &&
        (isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '18px',
              width: '80px',
            }}
          />
        ) : (
          <Badge
            className={styles.chip}
            content={content}
            color={mainColor}
            style={{
              display: 'inline-block',
            }}
            {...nest}
          />
        ))}
    </>
  );
}

export default React.memo(Chip);
