export enum STORAGE_KEY {
  ACCESS_TOKEN = 'access-token',
  REFRESH_TOKEN = 'refresh-token',
  ACCOUNT_INFO = 'account-info',
  BUILDING_ID = 'building-id',
  NOTIFY_TOKEN = 'notify-token',
  LANGUAGE = 'language',
  APP_VERSION = 'app-version',
  IS_OPEN_APP = 'is-open-app',
  APP_CONFIG = 'app-config',
}

export class LocalStorage {
  setStorageItem(key: STORAGE_KEY, data: any) {
    try {
      localStorage.setItem(key, JSON.stringify(data));

      return true;
    } catch {
      return false;
    }
  }

  getStorageItem(key: STORAGE_KEY) {
    try {
      const data = localStorage.getItem(key);
      if (data) return JSON.parse(data);
      else return null;
    } catch {
      return null;
    }
  }

  clearStorageItem(key: STORAGE_KEY) {
    try {
      localStorage.removeItem(key);

      return true;
    } catch {
      return false;
    }
  }

  clearAllStorage() {
    const clearKey = [
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_KEY.REFRESH_TOKEN,
      STORAGE_KEY.ACCOUNT_INFO,
      STORAGE_KEY.BUILDING_ID,
      STORAGE_KEY.NOTIFY_TOKEN,
    ];
    try {
      for (let i = 0; i < clearKey.length; i++) {
        localStorage.removeItem(clearKey[i]);
      }

      return true;
    } catch {
      return false;
    }
  }
}
