import useDirection from 'hooks/useDirection';
import styles from './styles.module.scss';
import ContractCard from 'components/card/ContractCard';
import { ROUTES } from 'constants/router';
import { CONTRACT_STATUS, DELAY_TRANSITION } from 'constants/common';
import { MdAssignment } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ContractInfoType } from 'types/user.type';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'store/Store';
import { propertySelector } from 'store/property';
import { getContractsPaging } from 'services/user.service';
import { formatDate, isSuccessCode } from 'utils/common';
import { ContractType } from 'types/common.type';
import ScrollWrapper from 'components/common/ScrollWrapper';
import EmptyCard from 'components/card/EmptyCard';

type Props = {};

export default function ContractContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [listContract, setListContract] = useState<ContractInfoType[]>([]);
  const { currentBuilding } = useSelector(propertySelector);
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentPage = useRef(1);
  const total = useRef(1);
  const limit = Math.ceil((window.innerHeight - 50 - 56) / (84 + 12) + 1);

  const calculateContractStatus = (isExpired?: boolean) => {
    if (isExpired) return CONTRACT_STATUS.EXPIRED;
    else return CONTRACT_STATUS.AVAILABLE;
  };

  const getContractList = async () => {
    if (currentBuilding?.id) {
      setIsLoadingContract(true);
      const response = await getContractsPaging({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        const newList = data.data as ContractInfoType[];
        setListContract(newList);
        total.current = data.total;
        setHasMore(newList.length < total.current);
      }
      setTimeout(() => {
        setIsLoadingContract(false);
      }, DELAY_TRANSITION);
    }
  };

  const handleLoadMore = useCallback(async () => {
    if (listContract.length < total.current && currentBuilding?.id && hasMore) {
      currentPage.current = currentPage.current + 1;
      setIsLoadingMore(true);
      const response = await getContractsPaging({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setTimeout(() => {
          setIsLoadingMore(false);
          const newList = listContract.concat(data.data as ContractInfoType[]);
          total.current = data.total;
          if (data.data.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(newList.length < total.current);
          }
          setListContract(newList);
        }, DELAY_TRANSITION);
      }
    }
  }, [currentBuilding?.id, listContract, total.current, hasMore]);

  useEffect(() => {
    getContractList();

    return;
  }, [currentBuilding?.id]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controller}>
        <p className="font-black font-md font-medium">{t('title.contract-list')}</p>
        {/* <MdFilterListAlt fontSize="24px" /> */}
      </div>
      <ScrollWrapper className={styles.list} callBack={isLoadingMore ? undefined : handleLoadMore}>
        {isLoadingContract ? (
          Array(limit)
            .fill(null)
            .map((_, i) => i)
            .map((ele) => {
              return <ContractCard key={ele} isSkeleton isHideChip />;
            })
        ) : (
          <>
            {listContract.length > 0 ? (
              listContract?.map((contract) => {
                return (
                  <ContractCard
                    key={contract.id}
                    contractCode={String(contract.id)}
                    expiredDate={formatDate(contract.endAt)}
                    room={contract.infoRoom?.room.name}
                    onClick={goTo(
                      ROUTES.DETAIL_CONTRACT.replace(':contractId', String(contract.id)),
                    )}
                    status={calculateContractStatus(contract.isExpired) as ContractType}
                  />
                );
              })
            ) : (
              <EmptyCard
                Icon={MdAssignment}
                text={t('paragraph.no-contract')}
                style={{
                  height: '56px',
                }}
              />
            )}
            {isLoadingMore &&
              Array(limit)
                .fill(null)
                .map((_, i) => i)
                .map((ele) => {
                  return <ContractCard key={ele} isSkeleton isHideChip />;
                })}
          </>
        )}
        <div
          style={{
            display: 'flex',
            paddingBottom: '200px',
          }}
        ></div>
      </ScrollWrapper>
    </div>
  );
}
