import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import useDirection from 'hooks/useDirection';
import CustomSelector from 'components/input/CustomSelector';
import useFormData from 'hooks/useFormData';
import CustomArea from 'components/input/CustomArea';
import TextButton from 'components/button/TextButton';
import { useTranslation } from 'react-i18next';
import { getMaintenanceTasks } from 'services/general.service';
import { TaskType } from 'types/general.type';
import { delayNavigate, isSuccessCode } from 'utils/common';
import { useSelector } from 'react-redux';
import { propertySelector } from 'store/property';
import { getAvailableContracts } from 'services/user.service';
import { ContractInfoType } from 'types/user.type';
import BottomSpace from 'components/common/BottomSpace';
import { sentRequestMaintenance } from 'services/kanban.service';
import { commonSelector } from 'store/common';
import AttachmentUploader from 'components/input/AttachmentUploader';
import { ImageType } from 'types/common.type';
import EmptyCard from 'components/card/EmptyCard';
import { MdAssignment } from 'react-icons/md';

type Props = {};

type FormData = {
  titleId: string;
  contractId: string;
  description: string;
  imageUrl: ImageType[];
};

const initFormData: FormData = {
  titleId: '',
  contractId: '',
  description: '',
  imageUrl: [],
};

export default function MaintenanceAddContainer({}: Props) {
  const { t } = useTranslation();
  const { goBack } = useDirection();
  const { KEY, formData, onChangeForm } = useFormData<FormData>(initFormData);
  const [loadingInit, setLoadingInit] = useState(false);
  const [listTask, setListTask] = useState<TaskType[]>([]);
  const [listContract, setListContract] = useState<ContractInfoType[]>([]);
  const { currentBuilding } = useSelector(propertySelector);
  const { isLoading } = useSelector(commonSelector);

  const getTaskList = async () => {
    setLoadingInit(true);
    const response = await getMaintenanceTasks();
    const { data, code } = response.data;
    if (isSuccessCode(code)) {
      setListTask(data);
      onChangeForm(KEY.TITLE_ID)(String(data[0].id));
    }
  };

  const getListContract = async () => {
    if (currentBuilding?.id) {
      setLoadingInit(true);

      const response = await getAvailableContracts({
        buildingId: currentBuilding?.id,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        if (data.length > 0) {
          setListContract(data as ContractInfoType[]);
          onChangeForm(KEY.CONTRACT_ID)(String(data[0]?.id));
        }
      } else {
        setListContract([]);
      }
      delayNavigate(() => setLoadingInit(false));
    }
  };

  const handleSendRequest = async () => {
    const response = await sentRequestMaintenance({
      ...formData,
      buildingId: currentBuilding?.id,
      imageUrl: formData.imageUrl?.map((image) => image.url),
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      delayNavigate(() => goBack());
    }
  };

  useEffect(() => {
    getTaskList();
  }, []);

  useEffect(() => {
    getListContract();
  }, [currentBuilding?.id]);

  return (
    <div className={styles.wrapper}>
      {listContract.length > 0 ? (
        <>
          <CustomSelector
            value={formData.contractId}
            onChange={onChangeForm(KEY.CONTRACT_ID)}
            title={t('title.room')}
            required
            isSkeleton={loadingInit}
            options={listContract.map((contract) => ({
              label: contract.infoRoom?.room.name,
              value: String(contract.id),
            }))}
          />
          <CustomSelector
            value={formData.titleId}
            onChange={onChangeForm(KEY.TITLE_ID)}
            title={t('title.item-maintenance')}
            required
            isSkeleton={loadingInit}
            options={listTask.map((task) => ({
              label: task.name,
              value: String(task.id),
            }))}
          />
          <CustomArea
            title={t('title.description')}
            value={formData.description}
            onChange={onChangeForm(KEY.DESCRIPTION)}
          />
          <AttachmentUploader
            title={t('title.attachment')}
            onChange={onChangeForm(KEY.IMAGE_URL)}
          />
          <BottomSpace />
          <div className={styles.button}>
            <TextButton
              title={t('button.sent-request')}
              variant="solid"
              color="warning"
              onClick={handleSendRequest}
              loading={isLoading}
            />
          </div>
        </>
      ) : (
        <>
          <EmptyCard Icon={MdAssignment} text={t('paragraph.no-contract')} />
        </>
      )}
    </div>
  );
}
