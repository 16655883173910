import WrapperLayout from 'layouts/WrapperLayout';
import MaintenanceContainer from 'containers/MaintenanceContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function MaintenancePage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.maintenance-request')}>
      <MaintenanceContainer />
    </WrapperLayout>
  );
}
