import React from 'react';
import styles from './styles.module.scss';
import { Skeleton, SpaceProps } from 'antd-mobile';
import { formatDateTimeNow, showData } from 'utils/common';
import { NOTIFY_TEMPLATE } from 'constants/notify';

type Props = {
  isRead?: boolean;
  title?: string;
  date?: string;
  isSkeleton?: boolean;
  type?: string;
  action?: string;
};

function NotifyCard({
  isRead = false,
  title = '',
  date = '',
  isSkeleton = false,
  type = '',
  action = '',
  ...nest
}: Props & SpaceProps) {
  return (
    <div
      className={`${styles.wrapper} notify-card`}
      style={{
        opacity: isRead ? 0.5 : 1,
      }}
      {...nest}
    >
      {isSkeleton ? (
        <Skeleton className={styles.iconBox} animated />
      ) : (
        <div
          className={styles.iconBox}
          style={{
            background:
              type &&
              action &&
              (NOTIFY_TEMPLATE as any)[type as keyof typeof NOTIFY_TEMPLATE][action].color,
          }}
        >
          {type &&
            action &&
            (NOTIFY_TEMPLATE as any)[type as keyof typeof NOTIFY_TEMPLATE][action].Icon}
        </div>
      )}
      <div className={styles.content}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-sm)',
            }}
          />
        ) : (
          <p className="font-black font-sm font-bold">{showData(title)}</p>
        )}
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-xs)',
              width: '30%',
            }}
          />
        ) : (
          <p className="color-grey-01 font-xs font-light">{showData(formatDateTimeNow(date))}</p>
        )}
      </div>
    </div>
  );
}

export default React.memo(NotifyCard);
