import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import EmptyCard from 'components/card/EmptyCard';
import { MdAccessTimeFilled } from 'react-icons/md';

type Props = {};

export default function ComingSoonContainer({}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <EmptyCard
        Icon={MdAccessTimeFilled}
        text={t('paragraph.coming-soon')}
        style={{
          height: '56px',
        }}
      />
      {/* <p className="font-xl font-black">{t('paragraph.coming-soon')}</p> */}
    </div>
  );
}
