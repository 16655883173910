import { Modal } from 'antd-mobile';
import styles from './styles.module.scss';
import React, { useEffect } from 'react';
import { MdOutlineWarning } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils/common';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  confirmTitle?: string;
};

function ConfirmModal({ isOpen, onClose, onConfirm, confirmTitle }: Props) {
  const { t } = useTranslation();

  const openModal = () => {
    Modal.confirm({
      className: styles.modal,
      header: <MdOutlineWarning color="var(--orange)" fontSize="50px" />,
      confirmText: t('button.confirm'),
      cancelText: t('button.cancel'),
      closeOnMaskClick: true,
      afterClose: onClose,
      onConfirm: onConfirm,
      maskStyle: { background: 'transparent' },

      bodyStyle: {
        boxShadow: 'var(--shadow-01)',
      },
      content: (
        <>
          <p
            className="font-md color-black"
            style={{
              textAlign: 'center',
            }}
          >
            {showData(confirmTitle)}
          </p>
        </>
      ),
    });
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
    }
  }, [isOpen]);

  return <></>;
}

export default React.memo(ConfirmModal);
