import { getMimeTypeFromBase64 } from 'utils/common';
import { AxiosGW } from './axios.service';

export const uploadImage = async (payload: { base64: string }) => {
  const mineType = getMimeTypeFromBase64(payload.base64);
  const formData = new FormData();
  const byteCharacters = atob(payload.base64.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  formData.append(
    'image',
    new Blob(byteArrays, { type: String(mineType) }),
    `image.${mineType?.split('/')[1]}`,
  );

  return AxiosGW.post('/api/v1/external/image/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    isDisableLoading: true,
    isDisableToast: true,
  });
};
