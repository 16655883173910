import { InputProps } from 'antd-mobile';
import styles from './styles.module.scss';
import React from 'react';
import { showData } from 'utils/common';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
  required?: boolean;
};
function CustomArea({
  title = '',
  placeholder = '',
  value = '',
  onChange,
  errorMessage = '',
  required = false,
  ...nest
}: Props & InputProps) {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <div className={styles.form}>
      <p className={`font-medium font-md color-black ${required ? 'required' : ''}`}>
        {showData(title)}
      </p>
      <textarea
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={nest.disabled}
      />
      {errorMessage && <p className="error-message">{t(`validate-message.${errorMessage}`)}</p>}
    </div>
  );
}

export default React.memo(CustomArea);
