import useDisclosure from 'hooks/useDisclosure';
import { getImageURL } from 'utils/common';
import { Image, ImageViewer, SpaceProps } from 'antd-mobile';
import React from 'react';

type Props = {
  url?: string;
};

function ImageCard({ url, ...rest }: Props & SpaceProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const image = getImageURL(String(url));

  return (
    <div {...rest}>
      <Image src={image} width="100%" height="100%" onClick={onOpen} />
      <ImageViewer
        classNames={{
          mask: 'customize-mask',
          body: 'customize-body',
        }}
        image={image}
        visible={isOpen}
        onClose={onClose}
      />
    </div>
  );
}

export default React.memo(ImageCard);
