import { useState } from 'react';

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

function useFormData<T>(initData: T) {
  type NumberKeys = keyof T;
  const [formData, setFormData] = useState<T>(initData);
  const listKey = Object.keys(initData as any) as Array<keyof T>;
  const tempFormKey: any = {};
  listKey.forEach((key) => {
    tempFormKey[
      String(key)
        .replace(/([A-Z])/g, '_$1')
        .toUpperCase()
    ] = key as string;
  });
  const KEY: { [key in NumberKeys as Uppercase<CamelToSnakeCase<string & key>>]: string } =
    tempFormKey;

  const onChangeForm = (key: string) => (value: any) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return { formData, KEY, onChangeForm };
}

export default useFormData;
