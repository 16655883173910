import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import InvoiceCard from 'components/card/InvoiceCard';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import { InvoiceInfoType } from 'types/user.type';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'store/Store';
import { propertySelector } from 'store/property';
import { getInvoicesPaging } from 'services/user.service';
import { formatPrice, isSuccessCode, showData } from 'utils/common';
import { ALL_OPTION, DELAY_TRANSITION, INVOICE_STATUS } from 'constants/common';
import ScrollWrapper from 'components/common/ScrollWrapper';
import EmptyCard from 'components/card/EmptyCard';
import { Skeleton } from 'antd-mobile';
import CapsuleTab from 'components/common/CapsuleTab';
import { MdFeed } from 'react-icons/md';

type Props = {};

const LIST_CHIP = [
  ALL_OPTION,
  INVOICE_STATUS.WAITING,
  INVOICE_STATUS.PARTIAL,
  INVOICE_STATUS.EXPIRED,
  INVOICE_STATUS.PAID,
  INVOICE_STATUS.CANCEL,
];

export default function InvoiceContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [listInvoice, setListInvoice] = useState<InvoiceInfoType[]>([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const { currentBuilding } = useSelector(propertySelector);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [status, setStatus] = useState(LIST_CHIP[0]);
  const currentPage = useRef(1);
  const total = useRef(1);
  const limit = Math.ceil((window.innerHeight - 50 - 56) / (84 + 12) + 1);
  const firstLoad = useRef(true);

  const handleChangeChipFilter = (newValue: string) => {
    setStatus(newValue);
    currentPage.current = 1;
    setListInvoice([]);
    window.scrollTo(0, 0);
  };

  const getInvoiceList = useCallback(async () => {
    if (currentBuilding?.id) {
      setIsLoadingInvoice(true);
      const response = await getInvoicesPaging({
        invoiceStatus: status,
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        const newList = data.data as InvoiceInfoType[];
        setListInvoice(newList);
        total.current = data.total;
        setHasMore(newList.length < total.current);
        setTotalDebit(Number(data?.totalDebitPrice));
      }
      setTimeout(() => {
        setIsLoadingInvoice(false);
      }, DELAY_TRANSITION);
    }
    firstLoad.current = false;
  }, [status, currentBuilding?.id, currentPage.current, limit]);

  const handleLoadMore = useCallback(async () => {
    if (listInvoice.length < total.current && currentBuilding?.id && hasMore) {
      currentPage.current = currentPage.current + 1;
      setIsLoadingMore(true);
      const response = await getInvoicesPaging({
        invoiceStatus: status,
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setTimeout(() => {
          setIsLoadingMore(false);
          const newList = listInvoice.concat(data.data as InvoiceInfoType[]);
          total.current = data.total;
          if (data.data.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(newList.length < total.current);
          }
          setListInvoice(newList);
        }, DELAY_TRANSITION);
      }
    }
  }, [currentBuilding?.id, listInvoice, total.current, hasMore]);

  useEffect(() => {
    getInvoiceList();
  }, [currentBuilding?.id, status]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controller}>
        {isLoadingInvoice && firstLoad.current ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-md)',
              width: '70%',
            }}
          />
        ) : (
          <p className="font-black font-md font-medium">
            {t('title.total-unpaid-invoice')}: {showData(formatPrice(totalDebit))}
          </p>
        )}
      </div>
      <div className={styles.filter}>
        <CapsuleTab
          value={status}
          options={LIST_CHIP.map((chip) => {
            return {
              key: chip,
              title: t(`chip.${chip}`),
            };
          })}
          onChangeValue={handleChangeChipFilter}
        />
      </div>

      <ScrollWrapper className={styles.list} callBack={isLoadingMore ? undefined : handleLoadMore}>
        {isLoadingInvoice ? (
          Array(limit)
            .fill(null)
            .map((_, i) => i)
            .map((ele) => {
              return <InvoiceCard key={ele} isSkeleton />;
            })
        ) : (
          <>
            {listInvoice.length > 0 ? (
              listInvoice?.map((invoice) => {
                return (
                  <InvoiceCard
                    key={invoice.id}
                    status={invoice.status}
                    invoiceCode={String(invoice.id)}
                    period={invoice.contractPeriod?.name}
                    totalPrice={Number(invoice.totalPrice)}
                    createdAt={invoice.createdAt}
                    onClick={goTo(ROUTES.DETAIL_INVOICE.replace(':invoiceId', String(invoice.id)))}
                  />
                );
              })
            ) : (
              <EmptyCard
                text={t('paragraph.no-invoice-found')}
                Icon={MdFeed}
                style={{
                  height: '56px',
                }}
              />
            )}
            {isLoadingMore &&
              Array(limit)
                .fill(null)
                .map((_, i) => i)
                .map((ele) => {
                  return <InvoiceCard key={ele} isSkeleton />;
                })}
          </>
        )}
        <div
          style={{
            display: 'flex',
            paddingBottom: '200px',
          }}
        ></div>
      </ScrollWrapper>
    </div>
  );
}
