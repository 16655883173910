import React, { ComponentType } from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils/common';
import { SpaceProps } from 'antd-mobile';
import { IconBaseProps } from 'react-icons';

type Props = {
  text?: string;
  Icon?: ComponentType<IconBaseProps>;
};

function EmptyCard({ text, Icon, ...nest }: Props & SpaceProps) {
  return (
    <div className={styles.contractCard} {...nest}>
      {Icon && <Icon fontSize="28px" color="var(--grey-02)" />}
      <p
        className="color-black font-xs"
        style={{
          textAlign: 'center',
        }}
      >
        {showData(text)}
      </p>
    </div>
  );
}

export default React.memo(EmptyCard);
