import { FCM_VAPID_KEY, FIREBASE_CONFIG } from 'constants/firebase';
import { initializeApp } from 'firebase/app';
import 'firebase/messaging';
import { Messaging, getMessaging, getToken, isSupported } from 'firebase/messaging';

const app = initializeApp(FIREBASE_CONFIG);

let messaging: Messaging | null = null;

isSupported().then((isAllow) => {
  if (isAllow) messaging = getMessaging(app);
});

export const getMessagingToken = async () => {
  let currentToken = '';
  if (!messaging) return;
  try {
    currentToken = await getToken(messaging, { vapidKey: FCM_VAPID_KEY });

    // console.log('FCM registration token', currentToken);
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

export const requestNotifyPermission = async () => {
  if (!('Notification' in window)) {
    // alert("This browser does not support desktop notification");
  } else if (Notification.permission === 'granted') {
    return 'granted';
  } else {
    return await Notification.requestPermission();
  }
};

export const isAllowNotify = () => {
  if (!('Notification' in window)) {
    return false;
  } else if (Notification.permission === 'granted') {
    return true;
  } else return false;
};
