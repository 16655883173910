import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { MdCancel, MdOutlineAdd } from 'react-icons/md';
import { fileToBase64, generateUUID, showData } from 'utils/common';
import { Image, SpinLoading } from 'antd-mobile';
import { uploadImage } from 'services/external.service';
import _ from 'lodash';
import { ImageType } from 'types/common.type';

type Props = {
  title?: string;
  required?: boolean;
  onChange?: (newList: ImageType[]) => void;
};

function AttachmentUploader({ title, required, onChange }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [imageList, setImageList] = useState<ImageType[]>([]);
  const [imageServerURL, setImageServerURL] = useState<ImageType[]>([]);

  const handleRemoveImage = (id: string) => () => {
    setImageList(imageList.filter((image) => image.id !== id));
    setImageServerURL(imageServerURL.filter((image) => image.id !== id));
  };

  const handleSelectFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList) {
      const result: any[] = [];
      const listPromise = [];
      for (let i = 0; i < fileList.length; i++) {
        const url = window.URL.createObjectURL(fileList[i]);
        const imageUUID = generateUUID();
        result.push({
          id: imageUUID,
          url,
        });
        const promise = new Promise<ImageType>((resolve) => {
          fileToBase64(fileList[i]).then((base64) => {
            uploadImage({ base64 }).then((response) => {
              const { filePath } = response.data.data;
              resolve({
                id: imageUUID,
                url: filePath,
              });
            });
          });
        });
        listPromise.push(promise);
      }
      e.target.value = '';
      setImageList(imageList.concat(result));
      const listURLResponse = await Promise.all(listPromise);
      setImageServerURL(imageServerURL.concat(listURLResponse));
    }
  };

  useEffect(() => {
    onChange && onChange(imageServerURL);
  }, [imageServerURL]);

  return (
    <div className={styles.wrapper}>
      <p className={`font-medium font-md color-black ${required ? 'required' : ''}`}>
        {showData(title)}
      </p>
      <div className={styles.content}>
        {imageList.map((image) => {
          const isLoading = _.isUndefined(imageServerURL.find((url) => url.id === image.id));

          return (
            <div
              key={image.id}
              className={styles.imageBox}
              style={{
                width: (window.innerWidth - 12 * 4) / 3,
                height: (window.innerWidth - 12 * 4) / 3,
              }}
            >
              <Image src={image.url} />
              {isLoading ? (
                <div className={styles.loading}>
                  <SpinLoading style={{ '--size': '32px', '--color': 'var(--white-01)' }} />
                </div>
              ) : (
                <>
                  <div className={styles.icon} onClick={handleRemoveImage(image.id)}>
                    <MdCancel fontSize="24px" color="var(--black)" />
                  </div>
                </>
              )}
            </div>
          );
        })}

        <div
          className={styles.addBox}
          style={{
            width: (window.innerWidth - 12 * 4) / 3,
            height: (window.innerWidth - 12 * 4) / 3,
          }}
          onClick={handleSelectFile}
        >
          <MdOutlineAdd fontSize="50px" color="var(--grey-01)" />
        </div>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          multiple
          style={{
            display: 'none',
          }}
          onChange={onChangeFile}
        />
      </div>
    </div>
  );
}

export default React.memo(AttachmentUploader);
