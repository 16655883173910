import { Image, Toast } from 'antd-mobile';
import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils/common';
import { useDispatch } from 'store/Store';
import { clearToast } from 'store/common';
import { useTranslation } from 'react-i18next';

const SUCCESS_ICON = '/images/icons/svg/success.svg';
const FAIL_ICON = '/images/icons/svg/fail.svg';

type Props = {
  isError?: boolean;
  code?: string | number;
  isOpen?: boolean;
  duration?: number;
};

function ToastOverlay({ isError = false, isOpen = false, duration = 1000, code = '' }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const message = useMemo(() => {
    if (isError) return t(`error-message-code.${code}`);
    else return t(`success-message-code.${code}`);
  }, [code, isError]);

  useEffect(() => {
    if (isOpen) {
      Toast.show({
        maskClassName: styles.toastWrapper,
        icon: <Image src={isError ? FAIL_ICON : SUCCESS_ICON} width="108px" height="108px" />,
        content: <p className="color-white font-medium font-sm">{showData(code ? message : '')}</p>,
        duration: duration,
        position: 'center',
        afterClose: () => {
          dispatch(clearToast());
        },
      });
    }
  }, [isOpen, isError, message, duration]);

  return <></>;
}

export default React.memo(ToastOverlay);
