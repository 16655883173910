import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { PasscodeInput, PasscodeInputRef } from 'antd-mobile';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import { MdLock } from 'react-icons/md';
import { useLocation } from 'react-router';
import { delayNavigate, isSuccessCode, showData } from 'utils/common';
import { verifyOTPCode } from 'services/auth.service';
import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';

type Props = {};

const Storage = new LocalStorage();

export default function VerifyOTPContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const location = useLocation();
  const refPasscode = useRef<PasscodeInputRef>(null);

  useEffect(() => {
    if (!location.state?.phoneNumber) goTo(ROUTES.LOGIN)();
  }, [location]);

  useEffect(() => {
    if (refPasscode.current) {
      refPasscode.current.focus();
    }
  }, []);

  const handleFill = async (val: string) => {
    if (refPasscode.current) {
      refPasscode.current.blur();
    }
    const response = await verifyOTPCode({
      code: val,
      phoneNumber: location.state?.phoneNumber,
    });
    const { code, data } = response.data;
    if (isSuccessCode(code)) {
      Storage.setStorageItem(STORAGE_KEY.ACCESS_TOKEN, data?.accessToken);
      delayNavigate(() => goTo(ROUTES.HOME)());
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <MdLock color="var(--white-01)" fontSize="40px" />
      </div>
      <div className={styles.content}>
        <p className="font-bold color-white font-xl">{t('title.verify-otp')}</p>
        <p
          className="font-light color-white font-sm"
          style={{
            opacity: 0.7,
          }}
        >
          {t('paragraph.otp-send-to')}
        </p>
        <p className="font-bold color-white font-lg">{showData(location.state?.phoneNumber)}</p>
      </div>
      <div className={styles.code}>
        <PasscodeInput ref={refPasscode} seperated length={4} plain onFill={handleFill} />
      </div>
    </div>
  );
}
