import { SendOTPReq, VerifyOTPCodeReq } from 'types/auth.type';
import { AxiosGW } from './axios.service';
import { LocalStorage, STORAGE_KEY } from 'utils/localStorage';

const Storage = new LocalStorage();

export const sendOTP = async (payload: SendOTPReq) => {
  return AxiosGW.post('/api/v1/auth/login/otp/send', payload);
};

export const verifyOTPCode = async (payload: VerifyOTPCodeReq) => {
  return AxiosGW.post('/api/v1/auth/login/otp/verify', payload);
};

export const getProfile = async () => {
  return AxiosGW.get('/api/v1/auth/profile', {
    isDisableToast: true,
    isDisableLoading: true,
  });
};

export const uploadAvatar = async (payload: { base64: string }) => {
  const formData = new FormData();
  const byteCharacters = atob(payload.base64.split(',')[1]);
  const accountInfo = Storage.getStorageItem(STORAGE_KEY.ACCOUNT_INFO);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  formData.append(
    'image',
    new Blob(byteArrays, { type: 'image/jpeg' }),
    `${accountInfo?.account?.id}-avatar.jpeg`,
  );

  return AxiosGW.post('/api/v1/auth/avatar/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
