import { JoiValidate } from 'utils/joi';
import Joi from 'joi';
import { useState } from 'react';

function useValidation<T>(schema: Joi.PartialSchemaMap<T>) {
  const initKeys = Object.keys(schema) as Array<keyof T>;
  const initErrorMessage: any = {};
  initKeys.forEach((key) => {
    initErrorMessage[key] = '';
  });
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{ [key in keyof T]: string }>(initErrorMessage);
  const validate = new JoiValidate(schema);

  const onValidate = (payload: T) => {
    const response = validate.validateSchema(payload);
    setIsError(response.isError);
    setErrorMessage(response.errorMessage);

    return response.isError;
  };

  return { isError, errorMessage, onValidate };
}

export default useValidation;
