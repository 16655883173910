import React from 'react';
import styles from './styles.module.scss';
import { MdAccountBalanceWallet, MdOutlineAttachMoney } from 'react-icons/md';
import { formatDateTimeNow, formatPrice, showData } from 'utils/common';
import { TRANSACTION_STATUS } from 'constants/common';
import { Skeleton, SpaceProps } from 'antd-mobile';

type Props = {
  status?: string;
  transactionType?: string;
  transactionAmount?: string | number;
  transactionDate?: string;
  isSkeleton?: boolean;
};

function TransactionCard({
  status,
  transactionType,
  transactionAmount,
  transactionDate,
  isSkeleton = false,
  ...nest
}: Props & SpaceProps) {
  return (
    <div className={styles.transactionCard} {...nest}>
      {isSkeleton ? (
        <Skeleton
          animated
          style={{
            height: '40px',
            width: '40px',
            borderRadius: '12px',
          }}
        />
      ) : (
        <div className={styles.iconBox}>
          {status === TRANSACTION_STATUS.INCOME ? (
            <MdAccountBalanceWallet fontSize="24px" color="var(--red)" />
          ) : (
            <MdOutlineAttachMoney fontSize="24px" color="var(--green)" />
          )}
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.left}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className="font-medium font-sm color-black text-overflow">
              {showData(transactionType)}
            </p>
          )}
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xs)',
              }}
            />
          ) : (
            <p className="font-light font-xs color-grey-01">
              {showData(transactionDate ? formatDateTimeNow(transactionDate) : '')}
            </p>
          )}
        </div>
        <div className={styles.right}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-lg)',
                width: '100px',
              }}
            />
          ) : (
            <p
              className={`font-bold font-lg text-overflow ${
                status === TRANSACTION_STATUS.INCOME ? 'color-red' : 'color-green'
              }`}
            >
              {status === TRANSACTION_STATUS.INCOME ? '-' : '+'}
              {showData(transactionAmount ? formatPrice(Number(transactionAmount)) : '')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(TransactionCard);
