import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import CommonReducer from './common/reducer';
import PropertyReducer from './property/reducer';

export const store = configureStore({
  reducer: {
    common: CommonReducer,
    property: PropertyReducer,
  },
});

const rootReducer = combineReducers({
  common: CommonReducer,
  property: PropertyReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
