export const THEME_COLOR = {
  BLUE: '#2196f3',
  ORANGE: '#ffbb64',
  GREEN: '#9ade7b',
  RED: '#ff6854',
  GRAY: '#b5b5b5',
};

export const CONTRACT_COLOR = {
  AVAILABLE: 'var(--green)',
  EXPIRED: 'var(--soft-red)',
};

export const INVOICE_COLOR = {
  WAITING_FOR_PAYMENT: 'var(--blue)',
  PAID: 'var(--green)',
  CANCELED: 'var(--grey-02)',
  PARTIAL: 'var(--soft-orange)',
  EXPIRED: 'var(--soft-red)',
};

export const TRANSACTION_COLOR = {
  INCOME: 'var(--soft-red)',
  EXPENSE: 'var(--green)',
};
