import { SpaceProps } from 'antd-mobile';
import React from 'react';

type Props = {
  callBack?: () => void;
  children?: React.ReactNode;
  isScrollToTop?: boolean;
};

const ScrollWrapper = ({ callBack, children, ...nest }: Props & SpaceProps) => {
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom) {
      callBack && callBack();
    }
  };

  return (
    <div
      onScroll={handleScroll}
      style={{
        background: 'var(--white-01)',
      }}
      {...nest}
    >
      {children}
    </div>
  );
};

export default React.memo(ScrollWrapper);
