import { getBuildings } from 'services/property.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListBuildingAction = createAsyncThunk(
  'property/getListBuildingAction',
  async () => {
    const response = await getBuildings();

    return response.data;
  },
);
