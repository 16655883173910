import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { MdClose, MdDevicesFold, MdFactCheck, MdFeed } from 'react-icons/md';
import { formatDateTime, formatPrice, showData } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { INVOICE_STATUS } from 'constants/common';
import { Skeleton, SpaceProps } from 'antd-mobile';
import Chip from 'components/common/Chip';

type Props = {
  status?: string;
  period?: string;
  totalPrice?: number;
  invoiceCode?: string;
  createdAt?: string;
  isSkeleton?: boolean;
  isHideChip?: boolean;
};

function InvoiceCard({
  status,
  period,
  totalPrice = 0,
  invoiceCode,
  createdAt = '',
  isSkeleton = false,
  isHideChip = false,
  ...nest
}: Props & SpaceProps) {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (status) {
      case INVOICE_STATUS.PAID:
        return 'var(--green)';
      case INVOICE_STATUS.PARTIAL:
        return 'var(--orange)';
      case INVOICE_STATUS.CANCEL:
        return 'var(--grey-02)';
      case INVOICE_STATUS.WAITING:
        return 'var(--blue)';
      case INVOICE_STATUS.EXPIRED:
        return 'var(--soft-red)';
      default:
        return 'var(--blue)';
    }
  }, [status]);

  const chipColor = useMemo(() => {
    switch (status) {
      case INVOICE_STATUS.PAID:
        return 'success';
      case INVOICE_STATUS.PARTIAL:
        return 'warning';
      case INVOICE_STATUS.CANCEL:
        return 'default';
      case INVOICE_STATUS.WAITING:
        return 'primary';
      case INVOICE_STATUS.EXPIRED:
        return 'danger';
      default:
        return 'primary';
    }
  }, [status]);

  const iconStatus = useMemo(() => {
    switch (status) {
      case INVOICE_STATUS.PAID:
        return <MdFactCheck fontSize="30px" color="var(--white-01)" />;
      case INVOICE_STATUS.PARTIAL:
        return <MdDevicesFold fontSize="30px" color="var(--white-01)" />;
      case INVOICE_STATUS.CANCEL:
        return <MdClose fontSize="30px" color="var(--white-01)" />;
      case INVOICE_STATUS.WAITING:
        return <MdFeed fontSize="30px" color="var(--white-01)" />;
      case INVOICE_STATUS.EXPIRED:
        return <MdFeed fontSize="30px" color="var(--white-01)" />;
      default:
        return null;
    }
  }, [status]);

  return (
    <div className={styles.invoiceCard} {...nest}>
      {isSkeleton ? (
        <Skeleton className={styles.iconBox} animated />
      ) : (
        <div
          className={styles.iconBox}
          style={{
            background: color,
          }}
        >
          {iconStatus}
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.group}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: '12px',
                width: '100%',
              }}
            />
          ) : (
            <p className="font-light font-xs color-grey-01">
              {t('title.payment-period')}: <span className="font-medium">{showData(period)}</span>
            </p>
          )}

          {!isHideChip && (
            <Chip color={chipColor} content={t(`chip.${status}`)} isSkeleton={isSkeleton} />
          )}
        </div>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '24px',
              width: '100%',
            }}
          />
        ) : (
          <p
            className="font-bold font-xxl"
            style={{
              color: color,
            }}
          >
            {showData(formatPrice(totalPrice))}
          </p>
        )}
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              height: '10px',
              width: '100%',
              display: 'block',
              textAlign: 'right',
            }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <p
              className="font-light font-us color-grey-01"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {showData(createdAt ? formatDateTime(createdAt) : '')}
            </p>
            <p
              className="font-light font-us color-grey-01"
              style={{
                fontStyle: 'italic',
                textAlign: 'right',
                display: 'block',
                width: '100%',
              }}
            >
              #{showData(invoiceCode)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(InvoiceCard);
