import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import TransactionDetailContainer from 'containers/TransactionDetailContainer';
import { useSelector } from 'react-redux';
import { commonSelector } from 'store/common';

type Props = {};

export default function TransactionDetailPage({}: Props) {
  const { transactionDetailColor } = useSelector(commonSelector);

  return (
    <WrapperLayout
      title={''}
      headerColor={transactionDetailColor}
      style={{
        background: transactionDetailColor,
      }}
    >
      <TransactionDetailContainer />
    </WrapperLayout>
  );
}
