import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';
import EditProfileContainer from 'containers/EditProfileContainer';

type Props = {};

export default function EditProfilePage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.edit-profile')}>
      <EditProfileContainer />
    </WrapperLayout>
  );
}
