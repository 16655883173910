import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import NotifyCard from 'components/card/NotifyCard';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import { isSuccessCode } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { NotifyInfoType } from 'types/notify.type';
import { useSelector } from 'store/Store';
import { propertySelector } from 'store/property';
import { getNotificationsPaging, readAll } from 'services/notify.service';
import { DELAY_TRANSITION } from 'constants/common';
import ScrollWrapper from 'components/common/ScrollWrapper';
import EmptyCard from 'components/card/EmptyCard';
import { FloatingBubble } from 'antd-mobile';
import { MdMarkEmailRead, MdNotifications } from 'react-icons/md';
import useDisclosure from 'hooks/useDisclosure';
import ConfirmModal from 'components/modal/ConfirmModal';

type Props = {};

export default function NotifyContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [listNotify, setListNotify] = useState<NotifyInfoType[]>([]);
  const { currentBuilding } = useSelector(propertySelector);
  const [isLoadingNotify, setIsLoadingNotify] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentPage = useRef(1);
  const total = useRef(1);
  const limit = Math.ceil((window.innerHeight - 56) / (65 + 12) + 1);

  const getNotifyList = async () => {
    if (currentBuilding?.id) {
      setIsLoadingNotify(true);
      const response = await getNotificationsPaging({
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        const newList = data.data as NotifyInfoType[];
        setListNotify(newList);
        total.current = data.total;
        setHasMore(newList.length < total.current);
      }
      setTimeout(() => {
        setIsLoadingNotify(false);
      }, DELAY_TRANSITION);
    }
  };

  const handleLoadMore = useCallback(async () => {
    if (listNotify.length < total.current && hasMore) {
      currentPage.current = currentPage.current + 1;
      setIsLoadingMore(true);
      const response = await getNotificationsPaging({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setTimeout(() => {
          setIsLoadingMore(false);
          const newList = listNotify.concat(data.data as NotifyInfoType[]);
          total.current = data.total;
          if (data.data.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(newList.length < total.current);
          }
          setListNotify(newList);
        }, DELAY_TRANSITION);
      }
    }
  }, [listNotify, total.current, hasMore]);

  const handleReadAllNotify = async () => {
    const response = await readAll();
    const { code } = response.data;
    if (isSuccessCode(code)) {
      setListNotify(
        listNotify.map((notify) => {
          return {
            ...notify,
            isRead: true,
          };
        }),
      );
    }
  };

  useEffect(() => {
    getNotifyList();
  }, [currentBuilding?.id]);

  return (
    <div className={styles.wrapper}>
      <ScrollWrapper className={styles.list} callBack={isLoadingMore ? undefined : handleLoadMore}>
        {isLoadingNotify ? (
          Array(limit)
            .fill(null)
            .map((_, i) => i)
            .map((ele) => {
              return <NotifyCard key={ele} isSkeleton />;
            })
        ) : (
          <>
            {listNotify.length > 0 ? (
              listNotify?.map((notify) => {
                return (
                  <NotifyCard
                    key={notify.id}
                    title={notify.template?.titleVi}
                    date={notify.createdAt}
                    isRead={notify.isRead}
                    isSkeleton={isLoadingNotify}
                    onClick={goTo(ROUTES.DETAIL_NOTIFY.replace(':notifyId', String(notify.id)))}
                    type={notify.template?.type}
                    action={notify.template?.action}
                  />
                );
              })
            ) : (
              <EmptyCard
                Icon={MdNotifications}
                text={t('paragraph.no-notify')}
                style={{
                  marginTop: '12px',
                  height: '56px',
                }}
              />
            )}
            {isLoadingMore &&
              Array(limit)
                .fill(null)
                .map((_, i) => i)
                .map((ele) => {
                  return <NotifyCard key={ele} isSkeleton />;
                })}
          </>
        )}
        <div
          style={{
            display: 'flex',
            paddingBottom: '200px',
          }}
        ></div>
      </ScrollWrapper>
      <FloatingBubble
        style={{
          '--initial-position-bottom': '24px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
          '--background': 'var(--blue)',
        }}
        onClick={onOpen}
      >
        <MdMarkEmailRead fontSize="24px" />
      </FloatingBubble>
      <ConfirmModal
        confirmTitle={t('paragraph.read-all')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleReadAllNotify}
      />
    </div>
  );
}
