import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import InvoiceDetailContainer from 'containers/InvoiceDetailContainer ';
import { useSelector } from 'store/Store';
import { commonSelector } from 'store/common';

type Props = {};

export default function InvoiceDetailPage({}: Props) {
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const { invoiceDetailColor } = useSelector(commonSelector);

  return (
    <WrapperLayout
      title={`${t('title.invoice-code')}: #${invoiceId}`}
      headerColor={invoiceDetailColor}
      style={{
        background: invoiceDetailColor,
      }}
    >
      <InvoiceDetailContainer />
    </WrapperLayout>
  );
}
