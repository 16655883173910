import useDirection from 'hooks/useDirection';
import styles from './styles.module.scss';
import { DELAY_TRANSITION } from 'constants/common';
import { MdCurrencyExchange } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import TransactionCard from 'components/card/TransactionCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getTransactionsPaging } from 'services/user.service';
import { useSelector } from 'react-redux';
import { propertySelector } from 'store/property';
import { ContractFeeTransaction } from 'types/user.type';
import { isSuccessCode } from 'utils/common';
import ScrollWrapper from 'components/common/ScrollWrapper';
import EmptyCard from 'components/card/EmptyCard';
import { ROUTES } from 'constants/router';

type Props = {};

export default function TransactionContainer({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [listTransaction, setListTransaction] = useState<ContractFeeTransaction[]>([]);
  const { currentBuilding } = useSelector(propertySelector);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentPage = useRef(1);
  const total = useRef(1);
  const limit = Math.ceil((window.innerHeight - 50 - 56) / (64 + 12) + 1);

  const getTransactionList = async () => {
    if (currentBuilding?.id) {
      setIsLoadingTransaction(true);
      const response = await getTransactionsPaging({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        const newList = data.data as ContractFeeTransaction[];
        setListTransaction(newList);
        total.current = data.total;
        setHasMore(newList.length < total.current);
      }
      setTimeout(() => {
        setIsLoadingTransaction(false);
      }, DELAY_TRANSITION);
    }
  };

  const handleLoadMore = useCallback(async () => {
    if (listTransaction.length < total.current && currentBuilding?.id && hasMore) {
      currentPage.current = currentPage.current + 1;
      setIsLoadingMore(true);
      const response = await getTransactionsPaging({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setTimeout(() => {
          setIsLoadingMore(false);
          const newList = listTransaction.concat(data.data as ContractFeeTransaction[]);
          total.current = data.total;
          if (data.data.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(newList.length < total.current);
          }
          setListTransaction(newList);
        }, DELAY_TRANSITION);
      }
    }
  }, [currentBuilding?.id, listTransaction, total.current, hasMore]);

  useEffect(() => {
    getTransactionList();
  }, [currentBuilding?.id]);

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.controller}> */}
      {/* <p className="font-black font-lg font-bold">Hôm nay</p> */}
      {/* <MdFilterListAlt fontSize="24px" /> */}
      {/* </div> */}
      <ScrollWrapper className={styles.list} callBack={isLoadingMore ? undefined : handleLoadMore}>
        {isLoadingTransaction ? (
          Array(limit)
            .fill(null)
            .map((_, i) => i)
            .map((ele) => {
              return <TransactionCard key={ele} isSkeleton />;
            })
        ) : (
          <>
            {listTransaction.length > 0 ? (
              listTransaction?.map((transaction) => {
                return (
                  <TransactionCard
                    key={transaction.id}
                    status={transaction.transactionType?.type}
                    transactionType={transaction.transactionType?.nameVi}
                    transactionAmount={transaction.transactionAmount}
                    transactionDate={transaction.purchaseDate}
                    onClick={goTo(
                      ROUTES.DETAIL_TRANSACTION.replace(':transactionId', String(transaction.id)),
                    )}
                  />
                );
              })
            ) : (
              <EmptyCard
                Icon={MdCurrencyExchange}
                text={t('paragraph.no-transaction')}
                style={{
                  height: '56px',
                }}
              />
            )}
            {isLoadingMore &&
              Array(limit)
                .fill(null)
                .map((_, i) => i)
                .map((ele) => {
                  return <TransactionCard key={ele} isSkeleton />;
                })}
          </>
        )}
        <div
          style={{
            display: 'flex',
            paddingBottom: '200px',
          }}
        ></div>
      </ScrollWrapper>
    </div>
  );
}
