import { CommonResType } from 'types/common.type';
import { AxiosGW } from './axios.service';
import { SendRequestReq } from 'types/kanban.type';

export const sentRequestMaintenance = async (payload: CommonResType & SendRequestReq) => {
  const { buildingId, contractId, description, titleId, imageUrl } = payload;

  return AxiosGW.post(`/api/v1/kanban/building/${buildingId}/task/request`, {
    contractId,
    description,
    titleId,
    imageUrl,
  });
};

export const getListRequestMaintenance = async (payload: CommonResType) => {
  const { buildingId, limit, page } = payload;

  return AxiosGW.get(`/api/v1/kanban/building/${buildingId}/tasks`, {
    params: { limit, page },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getDetailRequestMaintenance = async (payload: CommonResType) => {
  const { taskId } = payload;

  return AxiosGW.get(`/api/v1/kanban/task/${taskId}/detail`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};
