import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import MaintenanceCard from 'components/card/MaintenanceCard';
import useDirection from 'hooks/useDirection';
import { ROUTES } from 'constants/router';
import { FloatingBubble } from 'antd-mobile';
import { MdBuild, MdOutlinePlaylistAdd } from 'react-icons/md';
import { getListRequestMaintenance } from 'services/kanban.service';
import { useSelector } from 'react-redux';
import { propertySelector } from 'store/property';
import { MaintenanceRequestType } from 'types/kanban.type';
import { isSuccessCode } from 'utils/common';
import ScrollWrapper from 'components/common/ScrollWrapper';
import EmptyCard from 'components/card/EmptyCard';
import { DELAY_TRANSITION } from 'constants/common';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function MaintenanceContainer({}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { currentBuilding } = useSelector(propertySelector);
  const { goTo } = useDirection();
  const [requests, setRequests] = useState<MaintenanceRequestType[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const currentPage = useRef(1);
  const total = useRef(1);
  const limit = Math.ceil((window.innerHeight - 56) / (82 + 12) + 1);

  const getListRequest = async () => {
    if (currentBuilding?.id) {
      setIsLoading(true);
      const response = await getListRequestMaintenance({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        const newList = data.data as MaintenanceRequestType[];
        setRequests(newList);
        total.current = data.total;
        setHasMore(newList.length < total.current);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DELAY_TRANSITION);
    }
  };

  const handleLoadMore = useCallback(async () => {
    if (requests.length < total.current && hasMore) {
      currentPage.current = currentPage.current + 1;
      setIsLoadingMore(true);
      const response = await getListRequestMaintenance({
        buildingId: currentBuilding?.id,
        page: currentPage.current,
        limit: limit,
      });
      const { data, code } = response.data;
      if (isSuccessCode(code)) {
        setTimeout(() => {
          setIsLoadingMore(false);
          const newList = requests.concat(data.data as MaintenanceRequestType[]);
          total.current = data.total;
          if (data.data.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(newList.length < total.current);
          }
          setRequests(newList);
        }, DELAY_TRANSITION);
      }
    }
  }, [requests, total.current, hasMore]);

  useEffect(() => {
    getListRequest();
  }, [currentBuilding?.id]);

  return (
    <div className={styles.wrapper}>
      <ScrollWrapper className={styles.list} callBack={isLoadingMore ? undefined : handleLoadMore}>
        {isLoading ? (
          Array(limit)
            .fill(null)
            .map((_, i) => i)
            .map((ele) => {
              return <MaintenanceCard key={ele} isSkeleton />;
            })
        ) : (
          <>
            {requests.length > 0 ? (
              requests?.map((request) => {
                return (
                  <MaintenanceCard
                    key={request.id}
                    title={request.name}
                    status={request.tab?.name}
                    createdAt={request.createdAt}
                    isSkeleton={isLoading}
                    onClick={goTo(
                      `${ROUTES.DETAIL_MAINTENANCE.replace(':requestId', String(request.id))}`,
                    )}
                  />
                );
              })
            ) : (
              <EmptyCard
                Icon={MdBuild}
                text={t('title.no-maintenance')}
                style={{
                  height: '56px',
                }}
              />
            )}
            {isLoadingMore &&
              Array(limit)
                .fill(null)
                .map((_, i) => i)
                .map((ele) => {
                  return <MaintenanceCard key={ele} isSkeleton />;
                })}
          </>
        )}
        <div
          style={{
            display: 'flex',
            paddingBottom: '200px',
          }}
        ></div>
      </ScrollWrapper>

      <FloatingBubble
        style={{
          '--initial-position-bottom': '24px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
          '--background': 'var(--blue)',
        }}
        onClick={goTo(ROUTES.ADD_MAINTENANCE)}
      >
        <MdOutlinePlaylistAdd fontSize="24px" />
      </FloatingBubble>
    </div>
  );
}
