import React from 'react';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';
import TransactionContainer from 'containers/TransactionContainer';

type Props = {};

export default function TransactionPage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.transaction')}>
      <TransactionContainer />
    </WrapperLayout>
  );
}
