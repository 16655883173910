import { Form, Input, InputProps } from 'antd-mobile';
import styles from './styles.module.scss';
import React from 'react';
import { showData } from 'utils/common';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (newValue?: string) => void;
  errorMessage?: string;
};
function InputNumber({
  title = '',
  placeholder = '',
  value = '',
  onChange,
  errorMessage = '',
  ...nest
}: Props & InputProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Form className={`${styles.form} ${errorMessage ? styles.error : ''}`} layout="vertical">
        <Form.Item className={styles.title} label={showData(title)}>
          <Input
            className="color-black font-sm"
            type="tel"
            placeholder={placeholder}
            clearable
            value={value}
            onChange={onChange}
            {...nest}
          />
        </Form.Item>
      </Form>
      {errorMessage && <p className="error-message">{t(`validate-message.${errorMessage}`)}</p>}
    </div>
  );
}

export default React.memo(InputNumber);
