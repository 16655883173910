import { showData } from 'utils/common';
import React from 'react';
import styles from './styles.module.scss';
import { Selector, SelectorOption, Skeleton } from 'antd-mobile';

type Props = {
  required?: boolean;
  title?: string;
  value?: string;
  options?: SelectorOption<string>[];
  isSkeleton?: boolean;
  onChange?: (newValue: string) => void;
};

function CustomSelector({
  title,
  required,
  value = '',
  isSkeleton = true,
  options = [],
  onChange,
}: Props) {
  const handleChange = (value: string[]) => {
    onChange && value.length && onChange(value[0]);
  };

  return (
    <div className={styles.selectorWrapper}>
      <p className={`font-medium font-md color-black ${required ? 'required' : ''}`}>
        {showData(title)}
      </p>
      {isSkeleton ? (
        <Skeleton animated style={{ width: '100%', height: '37px' }} />
      ) : (
        <Selector value={[value]} options={options} onChange={handleChange} />
      )}
    </div>
  );
}

export default React.memo(CustomSelector);
