import MaintenanceAddContainer from 'containers/MaintenanceAddContainer';
import WrapperLayout from 'layouts/WrapperLayout';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function MaintenanceAddPage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.create-request')}>
      <MaintenanceAddContainer />
    </WrapperLayout>
  );
}
