import { Button, ButtonProps } from 'antd-mobile';
import React, { ComponentType } from 'react';
import { IconBaseProps } from 'react-icons';
import styles from './styles.module.scss';

type Props = {
  Icon: ComponentType<IconBaseProps>;
};

function IconButton({ Icon, ...nest }: Props & ButtonProps) {
  return (
    <Button className={styles.icon} shape="rounded" {...nest}>
      <Icon className="color-white" fontSize="24px" />
    </Button>
  );
}

export default React.memo(IconButton);
