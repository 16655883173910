import { CapsuleTabs } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  value?: string;
  options: {
    title?: string;
    key?: string;
  }[];
  onChangeValue?: (newValue: string) => void;
};

function CapsuleTab({ value = '', options = [], onChangeValue }: Props) {
  return (
    <CapsuleTabs className={styles.capsuleTab} activeKey={value || ''} onChange={onChangeValue}>
      {options.map((option) => {
        return <CapsuleTabs.Tab key={option.key} title={option.title} />;
      })}
    </CapsuleTabs>
  );
}
export default React.memo(CapsuleTab);
