import { Image } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { createPortal } from 'react-dom';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { THEME_COLOR } from 'constants/color';
import { getAppLogo, getAppName, getVersion, showData } from 'utils/common';

function OpenAppOverlay() {
  const [isShow, setIsShow] = useState(true);
  usePWATemplateColor(THEME_COLOR.BLUE);

  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 3500);
  }, []);

  return createPortal(
    isShow && (
      <div className={styles.container}>
        <div className={styles.logo}>
          <div
            style={{
              width: '100px',
              height: '100px',
              background: 'var(--white-01)',
              borderRadius: '100px',
              overflow: 'hidden',
              marginBottom: '24px',
              border: '2px solid var(--white-01)',
            }}
          >
            <Image
              src={getAppLogo()}
              alt="logo"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          <p className={`font-xxxl color-white font-bold ${styles.animation}`}>{getAppName()}</p>
        </div>
        <p className={`font-sm color-white ${styles.subTitle}`}>
          Version: {showData(getVersion())}
        </p>
      </div>
    ),
    document.body,
  );
}

export default React.memo(OpenAppOverlay);
