export const ROUTES = {
  HOME: '/home',

  NOTIFY: '/notify',
  DETAIL_NOTIFY: '/notify/:notifyId',

  PROFILE: '/profile',
  EDIT_PROFILE: '/profile/edit',
  HELP_PROFILE: '/profile/help',
  TERM_PROFILE: '/profile/term-condition',
  PRIVACY_PROFILE: '/profile/privacy',
  CONTACT_PROFILE: '/profile/contact',

  CONTRACT: '/contract',
  DETAIL_CONTRACT: '/contract/:contractId',

  INVOICE: '/invoice',
  DETAIL_INVOICE: '/invoice/:invoiceId',

  TRANSACTION: '/transaction',
  DETAIL_TRANSACTION: '/transaction/:transactionId',

  MAINTENANCE: '/maintenance',
  DETAIL_MAINTENANCE: '/maintenance/:requestId',
  ADD_MAINTENANCE: '/maintenance/add',

  DASHBOARDS: '/dashboards',
  ABOUT: '/about',

  LOGIN: '/login',
  VERIFY_OTP: '/login/verify',
};
