import { render } from 'react-dom';
import 'styles/global.scss';
import 'utils/i18n';
import App from './App';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/Store';
import { initConfigApp, isPWA } from './utils/common';
import InstallOverlay from './components/overlay/InstallOverlay';

initConfigApp();
const rootElement = document.getElementById('root');
render(
  <BrowserRouter>
    <Provider store={store}>{isPWA() ? <App /> : <InstallOverlay />}</Provider>
  </BrowserRouter>,
  rootElement,
);
