import React from 'react';
import styles from './styles.module.scss';
import { Avatar, Skeleton } from 'antd-mobile';
import Chip from 'components/common/Chip';
import { MdArrowForward } from 'react-icons/md';
import { formatDateTime, showData } from 'utils/common';
import { MAINTENANCE_STATUS } from 'constants/common';
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string;
  createdAt?: string;
  sourceStatus?: string;
  desStatus?: string;
  isSkeleton?: boolean;
};

function MaintainStatusHistoryCard({
  name,
  createdAt,
  sourceStatus = '',
  desStatus = '',
  isSkeleton = false,
}: Props) {
  const { t } = useTranslation();

  const getChipColor = (status: string) => {
    switch (status) {
      case MAINTENANCE_STATUS.WAIT_FOR_APPROVAL:
        return 'default';
      case MAINTENANCE_STATUS.APPROVED:
        return 'primary';
      case MAINTENANCE_STATUS.IN_PROGRESS:
        return 'warning';
      case MAINTENANCE_STATUS.ACCEPTANCE:
        return 'purple';
      case MAINTENANCE_STATUS.COMPLETED:
        return 'success';
      case MAINTENANCE_STATUS.REJECTED:
        return 'danger';
      default:
        return 'default';
    }
  };

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.avatarWrapper}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '40px',
            }}
          />
        ) : (
          <Avatar
            src=""
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '40px',
            }}
          />
        )}
        <div className={styles.info}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <p className="color-black font-sm font-bold">{showData(name)}</p>
          )}
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: 'var(--font-xs)',
              }}
            />
          ) : (
            <p className="color-grey-01 font-xs">{showData(formatDateTime(createdAt))}</p>
          )}
        </div>
      </div>

      <div className={styles.status}>
        <Chip
          color={getChipColor(sourceStatus)}
          content={t(`chip.${sourceStatus}`)}
          isSkeleton={isSkeleton}
        />
        <MdArrowForward color="var(--black)" fontSize="var(--font-lg)" />
        <Chip
          color={getChipColor(desStatus)}
          content={t(`chip.${desStatus}`)}
          isSkeleton={isSkeleton}
        />
      </div>
    </div>
  );
}

export default React.memo(MaintainStatusHistoryCard);
