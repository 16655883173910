import React from 'react';

type Props = {};

function BottomSpace({}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        width: 'auto',
        height: '200px',
      }}
    />
  );
}

export default React.memo(BottomSpace);
