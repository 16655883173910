import React, { Suspense } from 'react';

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
