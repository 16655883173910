import WrapperLayout from 'layouts/WrapperLayout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MaintenanceDetailContainer from 'containers/MaintenanceDetailContainer';

type Props = {};

export default function MaintenanceDetailPage({}: Props) {
  const { t } = useTranslation();

  return (
    <WrapperLayout title={t('title.request-detail')}>
      <MaintenanceDetailContainer />
    </WrapperLayout>
  );
}
